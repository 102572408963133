import * as types from '../../constants/ActionTypes';

const INIT_STATE = {
  cartLoader: false,
  cartData: [],
  orders: null,
  phpTokenData: null,
  paymentLoading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.CART_SHOW_LOADER: {
      return {
        ...state,
        cartLoader: true,
      };
    }
    case types.CART_HIDE_LOADER: {
      return {
        ...state,
        cartLoader: false,
      };
    }

    case types.ADD_TO_CART_SUCCESS: {
      const {cartData} = state;
      let alreadyExists = false;
      cartData.forEach(x => {
        if (x.section_id === action.payload.section_id) {
          alreadyExists = true;
          x.count++;
        }
      });
      if (!alreadyExists) {
        cartData.push({...action.payload, count: 1});
      }
      return {
        ...state,
        cartData: cartData,
        orders: null,
      };
    }

    case types.REMOVE_FROM_CART_SUCCESS: {
      return {
        ...state,
        cartData: action.payload,
        orders: null,
      };
    }

    case types.FETCH_CART_DATA_SUCCESS: {
      return {
        ...state,
        cartData: action.payload,
      };
    }

    case types.CLEAR_ORDER: {
      return {
        ...state,
        orders: null,
      };
    }

    case types.SEND_PAYMENT_DATA_SUCCESS: {
      return {
        ...state,
        orders: action.payload,
        cartData: [],
      };
    }

    case types.SET_PHP_TOKEN: {
      return {
        ...state,
        phpTokenData: action.payload,
      };
    }

    case types.PAYMENT_LOADER: {
      return {
        ...state,
        paymentLoading: action.payload,
      };
    }

    default:
      return state;
  }
};
