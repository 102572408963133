import * as types from '../../constants/ActionTypes';

const INIT_STATE = {
  dataLoader: false,
  productData: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.PRODUCT_SHOW_LOADER: {
      return {
        ...state,
        dataLoader: true,
      };
    }
    case types.PRODUCT_HIDE_LOADER: {
      return {
        ...state,
        dataLoader: false,
      };
    }

    case types.FETCH_PRODUCT_DATA_SUCCESS: {
      return {
        ...state,
        productData: action.payload,
      };
    }

    default:
      return state;
  }
};
