import * as CartType from '../../constants/ActionTypes';
import {all, select, fork, put, takeEvery, delay} from 'redux-saga/effects';
import {mainAxios} from 'util/Api';
import {
  cartLoaderHide,
  cartLoaderShow,
  addToCartSuccess,
  removeFromCartSuccess,
  fetchCartDataSuccess,
  setPhpToken,
  setPaymentLoading,
  sendPaymentDataSuccess,
} from '../actions/Cart';
import {errorHandler} from '../actions/Error';
import config from '../../config/config';
import {clearError, showError} from '../../redux/actions/Error';

// send payment details to php
export function* sendDataToPhpSaga({payload}) {
  try {
    //yield put(cartLoaderShow());
    const formData = new FormData();
    for (var key in payload) {
      formData.append(key, payload[key]);
    }
    yield put(setPaymentLoading(true));

    const phpDataResponse = yield mainAxios.post('/generate-payment-token', formData);
    // console.log(phpDataResponse.data)
    if (phpDataResponse.data && !phpDataResponse.data.token.includes('The application has encountered an error, error code')) {
      yield put(setPhpToken(phpDataResponse.data));
    } else {
      yield put(setPaymentLoading(false));
      yield put(showError({message: 'The application has encountered an error.', page: 'PaymentPage'}));
      //yield put(showError({message: phpDataResponse.data.replace(/<[^>]*>?/gm, ''), page: 'PaymentPage'}));
      yield delay(7000);
      yield put(clearError());
    }
  } catch (error) {
    yield put(setPaymentLoading(false));
    yield put(showError({message: error.response.data.message, page: 'PaymentPage'}));
    yield delay(7000);
    yield put(clearError());
  }
}

// send payment details to php fetch watcher
export function* sendDataToPhpWatcher() {
  yield takeEvery(CartType.SEND_PAYMENT_DATA_TO_PHP, sendDataToPhpSaga);
}
// Send payment details to server
export function* sendPaymmentDataToServer({payload}) {
  try {
    yield put(setPaymentLoading(true));
    const transactionDetailsResponse = yield mainAxios.post(`/capture-transaction-details`, payload);
    if (transactionDetailsResponse.status === 200) {
      const reduxState = yield select();
      const {cart} = reduxState;
      const {cartData} = cart;
      yield put(sendPaymentDataSuccess({...transactionDetailsResponse?.data, course_image: cartData[0]?.course_image}));
    }
  } catch (error) {
    if (!error.response) {
      yield put(errorHandler({error: error, page: ''}));
    }
  }
  yield put(setPaymentLoading(false));
}

// Send payment details to server fetch watcher
export function* sendPaymmentDataToWatcher() {
  yield takeEvery(CartType.SEND_PAYMENT_SUCCESS_DETAILS, sendPaymmentDataToServer);
}

// cart details fetch worker
export function* cartContentSaga() {
  try {
    yield put(cartLoaderShow());

    // const cartResponse = yield Axios.get(`/api/cart`);
    const cartResponse = yield mainAxios.get(`/products/${config.amerikooler.institute_Id}`);

    if (cartResponse.data) {
      yield put(fetchCartDataSuccess(cartResponse.data));
    }
  } catch (error) {
    if (!error.response) {
      yield put(errorHandler({error: error, page: ''}));
      yield put(cartLoaderHide());
    }
  }
  yield put(cartLoaderHide());
}

// Cart details fetch watcher
export function* fetchCartDataWatcher() {
  yield takeEvery(CartType.FETCH_CART_DATA, cartContentSaga);
}

// product details fetch worker
export function* addToCart({product}) {
  // console.log('saga product');
  // console.log(product);
  try {
    yield put(cartLoaderShow());
    //const productResponse = yield mainAxios.get(`/api/products`);
    //if (productResponse.data) {
    //yield put(addToCartSuccess(productResponse.data));
    yield put(addToCartSuccess(product));
    //}
  } catch (error) {
    if (!error.response) {
      yield put(errorHandler({error: error, page: ''}));
    }
  }
  yield put(cartLoaderHide());
}

// Cart details fetch watcher
export function* addToCartWatcher() {
  // console.log('addToCartWatcher');
  yield takeEvery(CartType.ADD_TO_CART, addToCart);
}

// remove cart product worker
export function* removeFromCart({item}) {
  try {
    yield put(cartLoaderShow());
    const reduxState = yield select();
    const {cart} = reduxState;
    const {cartData} = cart;
    const data = cartData.filter(x => x.section_id !== item.section_id);
    //const productResponse = yield mongoAxios.get(`/api/products`);
    //if (productResponse.data) {
    //yield put(addToCartSuccess(productResponse.data));
    yield put(removeFromCartSuccess(data));
    //}
  } catch (error) {
    if (!error.response) {
      yield put(errorHandler({error: error, page: ''}));
    }
  }
  yield put(cartLoaderHide());
}

// Cart details fetch watcher
export function* removeFromCartWatcher() {
  yield takeEvery(CartType.REMOVE_FROM_CART, removeFromCart);
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([fork(fetchCartDataWatcher), fork(sendDataToPhpWatcher), fork(addToCartWatcher), fork(removeFromCartWatcher), fork(sendPaymmentDataToWatcher)]);
}
