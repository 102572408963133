import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {userSignOut} from 'redux/actions/Auth';
import {fetchProfileData} from '../../redux/actions/Profile';
import {bindActionCreators} from 'redux';
import {Link} from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import {firstLetter} from 'util/Extra';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import Box from '@mui/material/Box';
import MenuItem from '@material-ui/core/MenuItem';
import {Skeleton} from '@mui/material';
import {Button, Typography} from '@material-ui/core';

const styles = theme => ({
  profileWrap: {
    '& ul': {
      width: '229px !important',
      marginTop: '5px !important',
    },
    textAlign: 'right',

    '& .header-skeleton': {
      width: '229px !important',
      background: '#c1c1c1',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      borderRadius: '5px',
      float: 'right',
      height: '93px',
      margin: '0px',
      marginTop: '-16px',
    },

    '& .userName': {
      color: 'black',
      fontSize: '20px',
    },

    '& button': {
      width: '229px',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      borderRadius: '2px',
      padding: '15px 45px 15px 20px',
      background: '#ffffff url(/img/select-drop-arrow.svg) no-repeat center right 20px',

      '& .MuiButton-label': {
        justifyContent: 'left',
        fontSize: '20px',
        lineHeight: '87%',
        color: '#393e43',
      },
    },
  },
  hideOnMob: {
    '@media only screen and (max-width:767px)': {
      display: 'none',
    },
  },
});

const UserInfo = ({fetchProfileData, history, match, classes, profileData, submitLoader, authUser, signinStatus, userSignOut, clientConfigration}) => {
  const {serverConfig} = !!clientConfigration && clientConfigration;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (!!authUser) {
      fetchProfileData();
    }
  }, []);

  return (
    <div className={classes.hideOnMob}>
      <div className={classes.profileWrap}>
        {submitLoader || !profileData ? (
          <Skeleton className="header-skeleton" animation="wave" />
        ) : (
          <Button ref={anchorRef} aria-controls={open ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={handleToggle}>
            <Typography noWrap className="userName">
              {`Hi, ${authUser === null ? 'Guest' : firstLetter(profileData.first_name)}`}
            </Typography>
          </Button>
        )}

        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex: '1000'}}>
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                  {!authUser && !signinStatus && (
                    <Grow in={true}>
                      <a href={serverConfig.institute_landingpage_url} style={{textDecoration: 'none'}}>
                        <MenuItem onClick={handleClose}>Home</MenuItem>
                      </a>
                    </Grow>
                  )}
                  {/* <Grow in={true}>
                    <Link to="/ecommerce/product" style={{textDecoration: 'none'}}>
                      <MenuItem onClick={handleClose}>Product</MenuItem>
                    </Link>
                  </Grow>
                  <Grow in={true}>
                    <Link to="/ecommerce/cart" style={{textDecoration: 'none'}}>
                      <MenuItem onClick={handleClose}>Cart</MenuItem>
                    </Link>
                  </Grow> */}
                  {authUser && signinStatus ? (
                    <Grow in={true} style={{transformOrigin: '0 0 0'}} timeout={1000} onClick={handleClose}>
                      <Link to="/" style={{textDecoration: 'none'}}>
                        <MenuItem
                          onClick={() => {
                            sessionStorage.removeItem('component_route');
                            userSignOut();
                          }}
                        >
                          Logout
                        </MenuItem>
                      </Link>
                    </Grow>
                  ) : (
                    <Grow in={true} style={{transformOrigin: '0 0 0'}} timeout={1000}>
                      <Link to="/signin" style={{textDecoration: 'none'}}>
                        <MenuItem onClick={handleClose}>Login</MenuItem>
                      </Link>
                    </Grow>
                  )}
                </Box>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popper>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      userSignOut,
      fetchProfileData,
    },
    dispatch,
  );
};

const mapStateToProps = ({auth, settings, profile}) => {
  const {userRole, authUser, signinStatus, clientConfigration} = auth;
  const {submitLoader, profileData} = profile;
  const {width} = settings;

  return {
    userRole,
    width,
    profileData,
    submitLoader,
    authUser,
    signinStatus,
    clientConfigration,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserInfo));
