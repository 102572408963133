import * as types from '../../constants/ActionTypes';
const INIT_STATE = {
  dataLoader: false,
  certifiedInstallerData: [],
  certifiedInstallerState: null,
  sendFooterMailStatus: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.CERTIFIED_INSTALLER_SHOW_LOADER: {
      return {
        ...state,
        dataLoader: true,
      };
    }
    case types.CERTIFIED_INSTALLER_HIDE_LOADER: {
      return {
        ...state,
        dataLoader: false,
      };
    }

    case types.FETCH_CERTIFIED_INSTALLER_DATA_SUCCESS: {
      return {
        ...state,
        certifiedInstallerData: action.payload,
      };
    }

    case types.DROPDOWN_STATE_LIST_SUCCESS: {
      return {
        ...state,
        certifiedInstallerState: action.payload,
      };
    }
    case types.SEND_MAIL_FROM_FOOTER_FLAG: {
      return {
        ...state,
        sendFooterMailStatus: action.payload,
      };
    }
    default:
      return state;
  }
};
