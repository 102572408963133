import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import './CertifiedInstaller.scss';
import {connect} from 'react-redux';
import {fetchCertifiedInstallerData, fetchDropdownStateList} from '../../redux/actions/CertifiedInstaller';
import SimpleHeader from 'components/SimpleHeader/SimpleHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import {bindActionCreators} from 'redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {sortOn} from '../../util/Extra';
import moment from 'moment';
import FooterForCertifiedInstaller from 'components/FooterForCertifiedInstaller/FooterForCertifiedInstaller';
const styles = theme => ({
  inputRoot: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 2,
      backgroundColor: '#FFFFFF',
    },
  },
  MuiInputLabel: {
    root: {
      color: '#636A72',
      FontFamily: 'FranklinGothic URW Cond',
    },
  },
});
const firstIndex = 0;
const pageSize = 9;
class CertifiedInstaller extends Component {
  constructor(props) {
    super(props);
    this.state = {
      originalData: [],
      filteredData: [],
      page: 1,
      data: [],
    };
  }

  componentDidMount() {
    this.props.fetchDropdownStateList('US');
    this.props.fetchCertifiedInstallerData();
    window.addEventListener(
      'popstate',
      () => {
        this.props.history.go(-2);
      },
      false,
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.originalData.length === 0) {
      return {
        originalData: nextProps.certifiedInstallerData,
        filteredData: nextProps.certifiedInstallerData,
        page: 1,
        data: nextProps.certifiedInstallerData.length > 0 ? nextProps.certifiedInstallerData.sort(sortOn('first_name')).slice(firstIndex, pageSize) : [],
      };
    } else return null;
  }

  handlePaginationChange = (event, value) => {
    const {filteredData} = this.state;

    this.setState({
      page: value,
      data: filteredData.slice(firstIndex + pageSize * (value - 1), pageSize * value),
    });
  };

  handlefilterChange = e => {
    const newFilterData = this.state.originalData.filter(item => (!!item.state ? item.state === e.target.value : false));

    if (e.target.value !== 'Select State') {
      this.setState({
        page: 1,
        data: newFilterData.slice(firstIndex, pageSize),
        filteredData: newFilterData,
      });
    } else {
      this.setState({
        page: 1,
        data: this.state.originalData.slice(firstIndex, pageSize),
        filteredData: this.state.originalData,
      });
    }
  };

  // autocomplete search state
  onTagsChange = (e, values) => {
    if (!!values) {
      const newFilterData = this.state.originalData.filter(item =>
        !!item.state ? item.state === values.state_code || item.state === values.state_name : false,
      );

      this.setState({
        page: 1,
        data: newFilterData.slice(firstIndex, pageSize),
        filteredData: newFilterData,
      });
    } else {
      this.setState({
        page: 1,
        data: this.state.originalData.slice(firstIndex, pageSize),
        filteredData: this.state.originalData,
      });
    }
  };

  render() {
    const {data, page, filteredData} = this.state;
    const {dataLoader, classes} = this.props;
    return (
      <div className="app-container">
        <div className="app-main-container">
          <SimpleHeader />
          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <div className="container-full">
                <div className="certified-installer-wrap">
                  <div className="certified-installer-head">
                    <div className="row">
                      <div className="col-md-9">
                        <h2 className="head-title">CERTIFIED INSTALLERS</h2>
                        {data.length > 0 ? (
                          <span className="pege-detail">
                            Showing {data.length} of {filteredData.length}
                          </span>
                        ) : null}
                      </div>
                      {/* <div className="col-md-6 mbl-hide"> */}
                      <div className="col-md-3">
                        {/* <NativeSelect onChange={this.handlefilterChange} input={<BootstrapInput />} name="state" style={{float: 'right'}}>
                          <option>Select State </option>
                          {certifiedInstallerState &&
                            certifiedInstallerState.map(list => (
                              <option key={list.state_name} value={list.state_code}>
                                {list.state_name}
                              </option>
                            ))}
                        </NativeSelect> */}
                        <Autocomplete
                          options={!!this.props.certifiedInstallerState ? this.props.certifiedInstallerState : []}
                          getOptionLabel={option => (typeof option !== 'string' ? option.state_name : option)}
                          className={classes.inputRoot}
                          onChange={this.onTagsChange}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label={`Select State`}
                              name={'state'}
                              // onChange={handleOnChange}
                              variant="outlined"
                              margin="normal"
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    {dataLoader ? (
                      <div
                        className="circularLoader"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '50vh',
                          width: '100%',
                        }}
                      >
                        <CircularProgress />
                      </div>
                    ) : data.length ? (
                      data.map((item, index) => (
                        <Card className="col-md-4 shadow-none" key={index} style={{padding: '0px'}}>
                          <CardContent className="certifiedInstaller-box" style={{margin: '15px'}}>
                            <div className="installer-name-wrap">
                              <h3>{item.first_name + ' ' + item.last_name}</h3>
                              <Typography gutterBottom>{`Licensed on: ${moment(item.certificate_generate_date).format('MM-DD-YYYY')}`}</Typography>
                            </div>
                            <Typography gutterBottom className="address-section">
                              <img className="" alt="icon" src={process.env.PUBLIC_URL + '../../img/address-icon.svg'} />
                              {item.address}
                            </Typography>
                            <Typography gutterBottom className="phone-section">
                              <img className="" alt="icon" src={process.env.PUBLIC_URL + '../../img/phone-icon-2.svg'} />
                              {item.phone}
                            </Typography>
                          </CardContent>
                        </Card>
                      ))
                    ) : (
                      <div className="row mt-5 justify-content-center align-items-center" style={{flex: 'auto', height: '250px'}}>
                        <h3 className="cert-no-rec-heading">No Records Found</h3>
                      </div>
                    )}
                  </div>
                  {data.length ? (
                    <div className="pegination-wrap">
                      <Pagination count={Math.ceil(filteredData.length / pageSize)} page={page} onChange={this.handlePaginationChange} />
                    </div>
                  ) : null}
                </div>
              </div>
              <FooterForCertifiedInstaller />
            </div>
          </main>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchCertifiedInstallerData,
      fetchDropdownStateList,
    },
    dispatch,
  );
};

const mapStateToProps = ({certifiedinstaller}) => {
  const {certifiedInstallerData, dataLoader, certifiedInstallerState} = certifiedinstaller;
  return {
    certifiedInstallerData,
    dataLoader,
    certifiedInstallerState,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CertifiedInstaller));
