import React from 'react';
import MaskedInput from 'react-text-mask';

export default function CustomMaskedInput(props) {
  const {inputRef, ...other} = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      guide={true}
    />
  );
}
