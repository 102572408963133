import * as CartType from '../../constants/ActionTypes';

//Fetch product data from here
export const fetchCartData = () => {
  return {
    type: CartType.FETCH_CART_DATA,
  };
};

export const fetchCartDataSuccess = productData => {
  return {
    type: CartType.FETCH_CART_DATA_SUCCESS,
    payload: productData,
  };
};

//Add product to cart
export const addToCart = product => {
  return {
    type: CartType.ADD_TO_CART,
    product: product,
  };
};

export const addToCartSuccess = product => {
  return {
    type: CartType.ADD_TO_CART_SUCCESS,
    payload: product,
  };
};

export const createOrder = product => {
  return {
    type: CartType.CREATE_ORDER,
    payload: product,
  };
};

export const clearOrder = () => {
  return {
    type: CartType.CLEAR_ORDER,
  };
};

//Remove product from cart
export const removeFromCart = item => {
  return {
    type: CartType.REMOVE_FROM_CART,
    item: item,
  };
};

export const removeFromCartSuccess = product => {
  return {
    type: CartType.REMOVE_FROM_CART_SUCCESS,
    payload: product,
  };
};

export const addToCartFail = errorMsg => {
  return {
    type: CartType.ADD_TO_CART_FAIL,
    payload: errorMsg,
  };
};

// Loader
export const cartLoaderShow = () => ({
  type: CartType.CART_SHOW_LOADER,
});

export const cartLoaderHide = () => ({
  type: CartType.CART_HIDE_LOADER,
});

export const setPaymentLoading = data => {
  return {
    type: CartType.PAYMENT_LOADER,
    payload: data,
  };
};

// Payment Checkout data
export const sendPaymentDataSuccess = data => {
  return {
    type: CartType.SEND_PAYMENT_DATA_SUCCESS,
    payload: data,
  };
};

// setPhpToken
export const setPhpToken = data => {
  return {
    type: CartType.SET_PHP_TOKEN,
    payload: data,
  };
};

// Payment Checkout data
export const sendPaymentDataToPhp = data => {
  return {
    type: CartType.SEND_PAYMENT_DATA_TO_PHP,
    payload: data,
  };
};
//  Send payment transaction details to server
export const sendPaymentSuccessDetails = data => {
  return {
    type: CartType.SEND_PAYMENT_SUCCESS_DETAILS,
    payload: data,
  };
};
