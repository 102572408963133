import {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {flushAppDetails} from '../redux/actions/Auth';

class Logout extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    const {history} = this.props;

    //session storage clean
    this.props.flushAppDetails();
    history.push(`/signin`);
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({flushAppDetails}, dispatch);
};

const mapStateToProps = ({auth}) => {
  const {authUser, signinStatus, userRole, employeeId} = auth;
  return {
    authUser,
    signinStatus,
    userRole,
    employeeId,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
