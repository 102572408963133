import * as CourseType from '../../constants/ActionTypes';

//Fetch profile data from here
export const fetchProfileData = () => {
  return {
    type: CourseType.FETCH_PROFILE_DATA,
  };
};

export const fetchProfileDataSuccess = profileData => {
  return {
    type: CourseType.FETCH_PROFILE_DATA_SUCCESS,
    payload: profileData,
  };
};

export const fetchProfileDataFail = errorMsg => {
  return {
    type: CourseType.FETCH_PROFILE_DATA_FAIL,
    payload: errorMsg,
  };
};

// Loader
export const profileLoaderShow = () => ({
  type: CourseType.PROFILE_SHOW_LOADER,
});

export const profileLoaderHide = () => ({
  type: CourseType.PROFILE_HIDE_LOADER,
});
