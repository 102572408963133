/* istanbul ignore file */
import axios from 'axios';
import config from '../config/config';

const configApi = {
  publicUrl: config.serverURL,
};

const getHeaders = () => {
  return {
    'Content-Type': 'application/json',
    'api-key': config.amerikooler.api_key,
    'institute-id': config.amerikooler.institute_Id,
  };
};

/* export default axios.create({
  baseURL: configApi.publicUrl, //YOUR_API_URL HERE
  headers: getHeaders(),
  timeout: 55000, // Wait for 55 seconds
}); */

const mainAxios = axios.create({
  baseURL: configApi.publicUrl, //YOUR_API_URL HERE
  headers: getHeaders(),
  timeout: 55000, // Wait for 55 seconds
});

const Axios = axios.create({
  baseURL: 'http://localhost:3000', //YOUR_API_URL HERE
  headers: getHeaders(),
  timeout: 55000, // Wait for 55 seconds
});

export {mainAxios, Axios};
