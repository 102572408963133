// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSED_NAV';
export const DRAWER_TYPE = 'DRAWER_TYPE';
export const FIXED_DRAWER = 'FIXED_DRAWER';
export const COLLAPSED_DRAWER = 'COLLAPSED_DRAWER';
export const MINI_DRAWER = 'MINI_DRAWER';
export const THEME_COLOR = 'THEME_COLOR';
export const DARK_THEME = 'DARK_THEME';
export const WINDOW_WIDTH = 'WINDOW_WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE';
export const CHANGE_DIRECTION = 'CHANGE_DIRECTION';

export const CHANGE_NAVIGATION_STYLE = 'CHANGE_NAVIGATION_STYLE';
export const HORIZONTAL_NAVIGATION = 'HORIZONTAL_NAVIGATION';
export const VERTICAL_NAVIGATION = 'VERTICAL_NAVIGATION';

export const HORIZONTAL_MENU_POSITION = 'HORIZONTAL_MENU_POSITION';
export const ABOVE_THE_HEADER = 'ABOVE_THE_HEADER';
export const INSIDE_THE_HEADER = 'INSIDE_THE_HEADER';
export const BELOW_THE_HEADER = 'BELOW_THE_HEADER';

//Contact Module const
export const FETCH_START = 'FETCH_START';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';

// Auth const
const USERENTITY = '[USER]';
export const SIGNIN_USER = `${USERENTITY} SIGNIN_USER`;
export const SIGNUP_USER = `${USERENTITY} SIGNUP_USER`;
export const VERIFY_USER_EMAIL_EXIST = `${USERENTITY} VERIFY_USER_EMAIL_EXIST`;
export const EMAIL_VERIFICATION_STATUE_CODE = `${USERENTITY} EMAIL_VERIFICATION_STATUE_CODE`;
export const SIGNOUT_USER = `${USERENTITY} SIGNOUT_USER`;
export const AUTO_SIGNIN = `${USERENTITY} AUTO_SIGNIN`;
export const SIGNIN_USER_SUCCESS = `${USERENTITY} SIGNIN_USER_SUCCESS`;
export const SIGNOUT_USER_SUCCESS = `${USERENTITY} SIGNOUT_USER_SUCCESS`;
export const FORGOT_PASSWORD_START = `${USERENTITY} FORGOT_PASSWORD_START`;
export const FLUSH_APP_DETAILS = `${USERENTITY} FLUSH_APP_DETAILS`;
export const FETCH_PCONFIG = `${USERENTITY} FETCH_PCONFIG`;
export const SET_CONFIG_LOADER = `${USERENTITY} SET_CONFIG_LOADER`;
export const SET_PCONFIG = `${USERENTITY} SET_PCONFIG`;
export const SET_SUPERVISOR_ID = `${USERENTITY} SET_SUPERVISOR_ID`;
export const SET_EMPLOYEE_ID = `${USERENTITY} SET_EMPLOYEE_ID`;
export const INIT_URL = '[APP] INIT_URL';
export const USER_DATA = 'USER_DATA';
export const USER_TOKEN_SET = 'USER_TOKEN_SET';
export const SET_UPDATE_PASSWORD_TOKEN = `${USERENTITY} SET_UPDATE_PASSWORD_TOKEN`;
export const UPDATE_PASSWORD_LOADER = `${USERENTITY} UPDATE_PASSWORD_LOADER`;
export const CHANGE_PASSWORD = `${USERENTITY} CHANGE_PASSWORD`;
export const UPDATE_PASSWORD_COMPLETED = `${USERENTITY} UPDATE_PASSWORD_COMPLETED`;
export const SHOW_REDIRECT_LINK_LOADER = `${USERENTITY} SHOW_REDIRECT_LINK_LOADER`;
export const HIDE_REDIRECT_LINK_LOADER = `${USERENTITY} HIDE_REDIRECT_LINK_LOADER`;
export const HAS_COURSE_RESPONSE = `${USERENTITY} HAS_COURSE_RESPONSE`;
export const SET_USER_IS_ADMIN = `${USERENTITY} SET_USER_IS_ADMIN`;
export const FETCH_DROPDOWN_CITY_LIST = `${USERENTITY} FETCH_DROPDOWN_CITY_LIST`;
export const DROPDOWN_CITY_LIST_SUCCESS = `${USERENTITY} DROPDOWN_CITY_LIST_SUCCESS`;
export const FETCH_CITY_STATE_BY_ZIP = `${USERENTITY} FETCH_CITY_STATE_BY_ZIP`;
export const CITY_STATE_DATA_BY_ZIP_SUCCESS = `${USERENTITY} CITY_STATE_DATA_BY_ZIP_SUCCESS`;
export const EMAIL_VERIFICATION = `${USERENTITY} EMAIL_VERIFICATION`;
export const SEND_EMAIL_VERIFICATION = `${USERENTITY} SEND_EMAIL_VERIFICATION`;
export const RESEND_EMAIL_VERIFICATION_LINK = `${USERENTITY} RESEND_EMAIL_VERIFICATION_LINK`;
export const SEND_PREFILLED_SIGNUP_VALUE = `${USERENTITY} SEND_PREFILLED_SIGNUP_VALUE`;
export const SAVE_CREATE_LEAD_RESPONSE_DATA = `${USERENTITY} SAVE_CREATE_LEAD_RESPONSE_DATA`;
export const SET_EMAIL_VERIFICATION_USER_ERROR_DATA = `${USERENTITY} SET_EMAIL_VERIFICATION_USER_ERROR_DATA`;
export const SHOW_RESEND_LINK_LOADER = `${USERENTITY} SHOW_RESEND_LINK_LOADER`;
export const HIDE_RESEND_LINK_LOADER = `${USERENTITY} HIDE_RESEND_LINK_LOADER`;
// App Loader
const UIENTITY = '[UI]';
export const ON_SHOW_LOADER = `${UIENTITY} ON_SHOW_LOADER`;
export const ON_HIDE_LOADER = `${UIENTITY} ON_HIDE_LOADER`;
export const SHOW_MESSAGE = `${UIENTITY} SHOW_MESSAGE`;
export const HIDE_MESSAGE = `${UIENTITY} HIDE_MESSAGE`;
export const SHOW_HIDE_IDLE_MODAL = `${UIENTITY} SHOW_HIDE_IDLE_MODAL`;

// Error Hnadling
const ERRORENTITY = '[ERROR]';
export const ERROR_DETAILS = `${ERRORENTITY} ERROR_DETAILS`;
export const ADD_ERRORR = `${ERRORENTITY} ADD_ERRORR`;
export const CLEAR_ERROR = `${ERRORENTITY} CLEAR_ERROR`;

//Profile data page
const PROFILEENTITY = '[PROFILE]';
export const FETCH_PROFILE_DATA = `${PROFILEENTITY} FETCH_PROFILE_DATA`;
export const FETCH_PROFILE_DATA_SUCCESS = `${PROFILEENTITY} FETCH_PROFILE_DATA_SUCCESS`;
export const FETCH_PROFILE_DATA_FAIL = `${PROFILEENTITY} FETCH_PROFILE_DATA_FAIL`;
export const PROFILE_SHOW_LOADER = `${PROFILEENTITY} PROFILE_SHOW_LOADER`;
export const PROFILE_HIDE_LOADER = `${PROFILEENTITY} PROFILE_HIDE_LOADER`;

//Product data page
const PRODUCTENTITY = '[PRODUCT]';
export const FETCH_PRODUCT_DATA = `${PRODUCTENTITY} FETCH_PRODUCT_DATA`;
export const FETCH_PRODUCT_DATA_SUCCESS = `${PRODUCTENTITY} FETCH_PRODUCT_DATA_SUCCESS`;
export const FETCH_PRODUCT_DATA_FAIL = `${PRODUCTENTITY} FETCH_PRODUCT_DATA_FAIL`;
export const PRODUCT_SHOW_LOADER = `${PRODUCTENTITY} PRODUCT_SHOW_LOADER`;
export const PRODUCT_HIDE_LOADER = `${PRODUCTENTITY} PRODUCT_HIDE_LOADER`;

//Cart data page
const CARTENTITY = '[CART]';
export const FETCH_CART_DATA = `${CARTENTITY} FETCH_CART_DATA`;
export const FETCH_CART_DATA_SUCCESS = `${CARTENTITY} FETCH_CART_DATA_SUCCESS`;
export const ADD_TO_CART = `${CARTENTITY} ADD_TO_CART`;
export const REMOVE_FROM_CART = `${CARTENTITY} REMOVE_FROM_CART`;
export const CART_SHOW_LOADER = `${CARTENTITY} CART_SHOW_LOADER`;
export const CART_HIDE_LOADER = `${CARTENTITY} CART_HIDE_LOADER`;
export const PAYMENT_LOADER = `${CARTENTITY} PAYMENT_LOADER`;
export const ADD_TO_CART_FAIL = `${CARTENTITY} ADD_TO_CART_FAIL`;
export const ADD_TO_CART_SUCCESS = `${CARTENTITY} ADD_TO_CART_SUCCESS`;
export const REMOVE_FROM_CART_SUCCESS = `${CARTENTITY} REMOVE_FROM_CART_SUCCESS`;
export const CREATE_ORDER = `${CARTENTITY} CREATE_ORDER`;
export const CLEAR_ORDER = `${CARTENTITY} CLEAR_ORDER`;

export const SEND_PAYMENT_INTENT_DATA = `${CARTENTITY} SEND_PAYMENT_INTENT_DATA`;
export const SEND_PAYMENT_DATA_SUCCESS = `${CARTENTITY} SEND_PAYMENT_DATA_SUCCESS`;
export const SEND_PAYMENT_DATA_TO_PHP = `${CARTENTITY} SEND_PAYMENT_DATA_TO_PHP`;
export const SET_PHP_TOKEN = `${CARTENTITY} SET_PHP_TOKEN`;
export const SEND_PAYMENT_SUCCESS_DETAILS = `${CARTENTITY} SEND_PAYMENT_SUCCESS_DETAILS`;

//Certified Installer page
const CERTIFIEDINSTALLERNTITY = '[CERTIFIEDINSTALLER]';
export const FETCH_CERTIFIED_INSTALLER_DATA = `${CERTIFIEDINSTALLERNTITY} FETCH_CERTIFIED_INSTALLER_DATA`;
export const FETCH_CERTIFIED_INSTALLER_DATA_SUCCESS = `${CERTIFIEDINSTALLERNTITY} FETCH_CERTIFIED_INSTALLER_DATA_SUCCESS`;
export const FETCH_DROPDOWN_STATE_LIST = `${CERTIFIEDINSTALLERNTITY} FETCH_DROPDOWN_STATE_LIST`;
export const DROPDOWN_STATE_LIST_SUCCESS = `${CERTIFIEDINSTALLERNTITY} DROPDOWN_STATE_LIST_SUCCESS`;
export const CERTIFIED_INSTALLER_SHOW_LOADER = `${CERTIFIEDINSTALLERNTITY} CERTIFIED_INSTALLER_SHOW_LOADER`;
export const CERTIFIED_INSTALLER_HIDE_LOADER = `${CERTIFIEDINSTALLERNTITY} CERTIFIED_INSTALLER_HIDE_LOADER`;
export const SEND_FOOTER_MAIL = `${CERTIFIEDINSTALLERNTITY} SEND_FOOTER_MAIL`;
export const SEND_MAIL_FROM_FOOTER_FLAG = `${CERTIFIEDINSTALLERNTITY} SEND_MAIL_FROM_FOOTER_FLAG`;
