import React, {useEffect} from 'react';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {connect} from 'react-redux';

const clearToast = () => {
  const toasts = document.getElementsByClassName('notification');
  for (let index = 0; index < toasts.length; index++) {
    toasts[index].click();
  }
};

class Toast {
  constructor(message = null, title = null, timeOut = 3000) {
    clearToast();
    this.message = message;
    this.title = title;
    this.timeOut = timeOut;
  }
  info() {
    NotificationManager.info(this.message, this.title, this.timeOut);
  }
  success() {
    NotificationManager.success(this.message, this.title, this.timeOut);
  }
  warning() {
    NotificationManager.warning(this.message, this.title, this.timeOut);
  }
  error() {
    NotificationManager.error(this.message, this.title, this.timeOut);
  }
}

const info = (message, title, timeOut) => {
  const toast = new Toast(message, title, timeOut);
  toast.info();
};

const success = (message, title, timeOut) => {
  const toast = new Toast(message, title, timeOut);
  toast.success();
};

const warning = (message, title, timeOut) => {
  const toast = new Toast(message, title, timeOut);
  toast.warning();
};

const error = (message, title, timeOut) => {
  const toast = new Toast(message, title, timeOut);
  toast.error();
};

const ToastContainer = ({router}) => {
  useEffect(() => {
    if (router?.location?.pathname === '/signin') return;
    clearToast();
  }, [router]);

  return <NotificationContainer />;
};

const mapStateToProps = ({router}) => {
  return {
    router,
  };
};

export const ToastManager = {info, success, warning, error};
export default connect(mapStateToProps, null)(ToastContainer);
