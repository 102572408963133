import React from 'react';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import SimpleHeader from 'components/SimpleHeader/SimpleHeader';
import {COLLAPSED_DRAWER, FIXED_DRAWER} from 'constants/ActionTypes';
import {isIOS, isMobile} from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import {withStyles} from '@material-ui/core/styles';
import PaymentSuccess from './routes/PaymentSuccess/PaymentSuccess';
import HeaderAlert from '../components/HeaderAlert/HeaderAlert';

const styles = theme => ({
  root: {
    height: '100%',
    flex: '1 1',
  },
});
class App extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      isTimedOut: false,
    };
    this.idleTimer = null;
  }

  render() {
    // const {match, drawerType, navigationStyle, userRole, idleModal, clientConfigration} = this.props;
    const {match, drawerType, profileData} = this.props;
    const {hasVerifiedEmail} = !!profileData && profileData;

    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height');
    } else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height');
    }
    return (
      <div className={`app-container abx ${drawerStyle}`}>
        <div className="app-main-container">
          <>
            {!hasVerifiedEmail && <HeaderAlert />}
            <SimpleHeader />
          </>
          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <React.Fragment>
                <Switch>
                  <Redirect exact from={`${match.url}/`} to={`${match.url}/payment`} />
                  <Route path={`${match.url}/payment`} component={asyncComponent(() => import('./routes/PaymentCheckout/PaymentCheckout'))} />
                  <Route path={`${match.url}/paymentsuccess`} component={PaymentSuccess} />
                  <Route component={asyncComponent(() => import('components/Error404/Error404'))} />
                </Switch>
              </React.Fragment>
            </div>
          </main>
          {/* <Footer /> */}
          <div style={{position: 'absolute', left: '0px', bottom: '0px'}} className="hideOnMobile footer-img">
            <img className="sideHeaderImage" alt="icon" src={process.env.PUBLIC_URL + '../../../../img/side-footer.svg'} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({settings, auth, profile, commonData, cart}) => {
  const {drawerType, navigationStyle} = settings;
  const {userRole, clientConfigration} = auth;
  const {idleModal} = commonData;
  const {publishableKey} = cart;
  const {profileData} = profile;

  return {
    drawerType,
    navigationStyle,
    userRole,
    idleModal,
    clientConfigration,
    publishableKey,
    profileData,
  };
};
export default withRouter(connect(mapStateToProps, null)(withStyles(styles)(App)));
