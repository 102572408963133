import React, {useEffect} from 'react';
import './ThankyouCreateLeadsPopup.scss';
import GreetingHeader from 'components/GreetingHeader/GreetingHeader';
import {connect} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import getQueryParams from 'util/QueryString';
import {sendPrefilledSignupValue} from '../../redux/actions/Auth';
import {bindActionCreators} from 'redux';
import {formatPhoneNumber, getFirstAndLastName} from 'util/Extra';

const ThankyouCreateLeadsPopup = props => {
  const {signinStatus, authUser, userRole, loader, history, location, createLeadResponseData} = props;

  useEffect(() => {
    const userName = getQueryParams(location.search, 'name');
    const email = decodeURIComponent(getQueryParams(location.search, 'email'));
    const company = getQueryParams(location.search, 'company');
    const phoneNumber = getQueryParams(location.search, 'phone');
    const fromSource = getQueryParams(location.search, 'source');

    const FLName = getFirstAndLastName(userName);
    const firstUserName = FLName[0];
    const lastUserName = FLName[1];
    const userPhoneNumber = !!phoneNumber ? formatPhoneNumber(phoneNumber.replace(/\+/g, ' ')) : '';
    const userCompany = !!company ? company.replace(/\+/g, ' ') : '';

    if (firstUserName !== '' && email !== '' && userPhoneNumber !== '' && fromSource !== '') {
      props.sendPrefilledSignupValue({
        first_name: firstUserName,
        last_name: lastUserName,
        email: email,
        company: userCompany,
        phone: userPhoneNumber,
        from_source: fromSource,
      });
    }
    sessionStorage.setItem('name1', firstUserName);
    sessionStorage.setItem('name2', lastUserName);
    sessionStorage.setItem('email_id', email);
    sessionStorage.setItem('phone_number', userPhoneNumber);
    sessionStorage.setItem('company', userCompany);

    if (authUser !== null && signinStatus && userRole !== null) {
      history.push(`/app`);
    }
  }, []);

  useEffect(() => {
    const {signinStatus, history, authUser, userRole} = props;
    if (authUser !== null && signinStatus && userRole !== null) {
      history.push(`/app`);
    }
  }, [props]);

  return (
    <React.Fragment>
      <div className="app-container pre-thankyou-wrapper">
        <div className="app-main-container">
          <div className="header-logo-hideInMobile">
            <GreetingHeader />
          </div>

          {loader ? (
            <div className="loaderForConfigrationPage">
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="container d-flex flex-column justify-content-center vh-100 ">
                <div className="row justify-content-center">
                  <div className="col-md-7 ">
                    <div className="card">
                      <div className="card-content">
                        <div className="parent-frame-icon">
                          <div className="success-circle">
                            <img src="../img/success-icon.png" alt="success" />
                          </div>
                        </div>
                        {!!createLeadResponseData && createLeadResponseData.existing_lead ? (
                          <div className="heading">
                            <span>
                              {' '}
                              Thank you for contacting us, we already have your request details, one of our representatives will reach out to you shortly.
                            </span>
                            <hr />
                            <div className="box-content alert-box-content">
                              <p>Sign up today.</p>
                            </div>
                          </div>
                        ) : (
                          <div className="heading">
                            <span> Thank you for contacting us, one of our representatives will reach out to you shortly.</span>
                            <hr />
                            <div className="box-content alert-box-content">
                              <p>Sign up today.</p>
                            </div>
                          </div>
                        )}
                        <button
                          type="button"
                          className="default-btn"
                          disabled={loader}
                          onClick={() => {
                            history.push('/signup');
                          }}
                        >
                          Get Certified Now
                        </button>
                        <div className="signin-link">
                          Already Have An Account? <a href="/signin">Login Here</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{position: 'absolute', left: '0px', bottom: '0px'}} className="hideOnMobile">
                <img className="sideHeaderImage" alt="icon" src={process.env.PUBLIC_URL + '../../../../img/side-footer.svg'} />
              </div>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      sendPrefilledSignupValue,
    },
    dispatch,
  );
};
const mapStateToProps = ({auth}) => {
  const {loader, signinStatus, authUser, userRole, createLeadResponseData} = auth;

  return {
    loader,
    signinStatus,
    authUser,
    userRole,
    createLeadResponseData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThankyouCreateLeadsPopup);
