import * as types from '../../constants/ActionTypes';

const INIT_STATE = {
  submitLoader: false,
  profileData: {
    first_name: '',
    last_name: '',
    email: '',
   company_name: '',
   address: '',
    state: '',
   city: '',
   zip:'',
   phone:'' ,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.PROFILE_SHOW_LOADER: {
      return {
        ...state,
        submitLoader: true,
      };
    }
    case types.PROFILE_HIDE_LOADER: {
      return {
        ...state,
        submitLoader: false,
      };
    }

    case types.FETCH_PROFILE_DATA_SUCCESS: {
      return {
        ...state,
        profileData: action.payload,
      };
    }

    default:
      return state;
  }
};
