import * as commonErrorActions from '../../constants/ActionTypes';

// Common error handling
export const errorHandler = errorDetails => ({
  type: commonErrorActions.ERROR_DETAILS,
  payload: errorDetails,
});
// Common error handling
export const clearError = error => ({
  type: commonErrorActions.CLEAR_ERROR,
});
// Common error handling
export const showError = errorDetails => ({
  type: commonErrorActions.ADD_ERRORR,
  payload: errorDetails,
});
