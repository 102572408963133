// Header component for common header

import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import './GreetingHeader.scss';
import {connect} from 'react-redux';

const GreetingHeader = props => {
  const {clientConfigration} = props;
  const {serverConfig} = !!clientConfigration && clientConfigration;
  return (
    <div className="greetingHeader-wrapper">
      <React.Fragment>
        <CssBaseline />
        <header className="header-wrap">
          <div className="container-full">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="logo-wrap">
                  {!!props.val && props.val === 'ext' ? (
                    <img className="logo-img" alt="icon" src={serverConfig.institute_logo} />
                  ) : (
                    <img className="logo-img" alt="icon" src={serverConfig.institute_logo} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </header>
      </React.Fragment>
    </div>
  );
};

const mapStateToProps = ({auth}) => {
  const {clientConfigration} = auth;

  return {
    clientConfigration,
  };
};
export default connect(mapStateToProps, null)(GreetingHeader);
