const bool = (value, defaultValue) => (value === undefined ? defaultValue : value);

const production = {
  serverURL: process.env.REACT_APP_SERVER_URL,
  cryptoKey: process.env.REACT_APP_CRYPTO_KEY || '25c6c7ff35b9979b151f2136cd13b0ff',
  GENERATE_SOURCEMAP: bool(process.env.GENERATE_SOURCEMAP, false),
  sessionTimeoutMinuteValue: process.env.REACT_APP_SESSION_TIMEOUT_MIN_VALUE,
  recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,

  amerikooler: {
    api_key: process.env.REACT_APP_AMERIKOOLER_API_KEY,
    institute_Id: '2',
    themeColor: process.env.REACT_APP_AMERIKOOLER_THEME,
    paymentGatewayUrl: process.env.REACT_APP_AMERIKOOLER_PG_URL
  },
};

export default production;
