import * as authType from '../../constants/ActionTypes';

export const setInitUrl = url => {
  return {
    type: authType.INIT_URL,
    payload: url,
  };
};

// Used to show loader for partner configuration
export const setConfigLoader = loader => ({
  type: authType.SET_CONFIG_LOADER,
  payload: loader,
});

// Fetch partner configuration details from server action creator
export const fetchPconfig = code => ({
  type: authType.FETCH_PCONFIG,
  payload: code,
});

// Set partner config
export const setPconfig = config => ({
  type: authType.SET_PCONFIG,
  payload: config,
});

export const userSignIn = user => ({
  type: authType.SIGNIN_USER,
  payload: {
    ...user,
  },
});

export const userSignUp = user => ({
  type: authType.SIGNUP_USER,
  payload: {
    ...user,
  },
});

export const userAutoSignIn = details => {
  return {
    type: authType.AUTO_SIGNIN,
    payload: details,
  };
};

export const userSignInSuccess = user => ({
  type: authType.SIGNIN_USER_SUCCESS,
  payload: user,
});

export const setSupervisorId = user => ({
  type: authType.SET_SUPERVISOR_ID,
  payload: user,
});

export const setEmployeeId = user => ({
  type: authType.SET_EMPLOYEE_ID,
  payload: user,
});

// reset entire app.
export const flushAppDetails = () => ({
  type: authType.FLUSH_APP_DETAILS,
});

export const userSignOut = () => ({
  type: authType.SIGNOUT_USER,
});

export const userSignOutSuccess = () => ({
  type: authType.SIGNOUT_USER_SUCCESS,
});

export const authLoaderShow = () => ({
  type: authType.ON_SHOW_LOADER,
});

export const authLoaderHide = () => ({
  type: authType.ON_HIDE_LOADER,
});
export const showRedirectLinkLoader = () => ({
  type: authType.SHOW_REDIRECT_LINK_LOADER,
});

export const hideRedirectLinkLoader = () => ({
  type: authType.HIDE_REDIRECT_LINK_LOADER,
});

export const showResendLinkLoader = () => ({
  type: authType.SHOW_RESEND_LINK_LOADER,
});

export const hideResendLinkLoader = () => ({
  type: authType.HIDE_RESEND_LINK_LOADER,
});

export const showAuthMessage = message => ({
  type: authType.SHOW_MESSAGE,
  payload: message,
});

export const hideMessage = () => ({
  type: authType.HIDE_MESSAGE,
});

export const forgotPasswordStart = loginDetails => ({
  type: authType.FORGOT_PASSWORD_START,
  payload: loginDetails,
});

// loader for update password.
export const setPasswordUpdateLoader = flag => ({
  type: authType.UPDATE_PASSWORD_LOADER,
  payload: flag,
});

// email link token set
export const setUpdatePasswordToken = details => ({
  type: authType.SET_UPDATE_PASSWORD_TOKEN,
  payload: {...details},
});

// change passwored to new password.
export const updatePassword = details => ({
  type: authType.CHANGE_PASSWORD,
  payload: {...details},
});

// Change Password - new password updated.
export const changePasswordCompleted = flag => ({
  type: authType.UPDATE_PASSWORD_COMPLETED,
  payload: flag,
});

// Enrolled course response
export const hasCourseResponse = flag => ({
  type: authType.HAS_COURSE_RESPONSE,
  payload: flag,
});

// Enrolled course response
export const setUserIsAdmin = flag => ({
  type: authType.SET_USER_IS_ADMIN,
  payload: flag,
});

// Fetch Dropdown city list
export const fetchDropdownCityList = state => {
  return {
    type: authType.FETCH_DROPDOWN_CITY_LIST,
    payload: state,
  };
};

export const dropdownCityListSuccess = dropDownCityList => {
  return {
    type: authType.DROPDOWN_CITY_LIST_SUCCESS,
    payload: dropDownCityList,
  };
};

// Fetch city state by zipcode
export const fetchCityStateByZip = zip => {
  return {
    type: authType.FETCH_CITY_STATE_BY_ZIP,
    payload: zip,
  };
};

export const cityStateDataByZipSuccess = cityStateName => {
  return {
    type: authType.CITY_STATE_DATA_BY_ZIP_SUCCESS,
    payload: cityStateName,
  };
};

export const emailVerification = data => {
  return {
    type: authType.EMAIL_VERIFICATION,
    payload: data,
  };
};
export const sendEmailVerification = data => {
  return {
    type: authType.SEND_EMAIL_VERIFICATION,
    payload: data,
  };
};
export const signupUserEmailVerified = emailverifiedstatus => {
  return {
    type: authType.VERIFY_USER_EMAIL_EXIST,
    payload: emailverifiedstatus,
  };
};
export const emailVerificationStatusCode = emailverifiedstatuscode => {
  return {
    type: authType.EMAIL_VERIFICATION_STATUE_CODE,
    payload: emailverifiedstatuscode,
  };
};

export const resendEmailVerificationLink = data => {
  return {
    type: authType.RESEND_EMAIL_VERIFICATION_LINK,
    payload: data,
  };
};
export const sendPrefilledSignupValue = data => {
  return {
    type: authType.SEND_PREFILLED_SIGNUP_VALUE,
    payload: data,
  };
};

export const saveCreateLeadRespondeData = data => {
  return {
    type: authType.SAVE_CREATE_LEAD_RESPONSE_DATA,
    payload: data,
  };
};
export const setEmailVerificationUserErrorData = data => {
  return {
    type: authType.SET_EMAIL_VERIFICATION_USER_ERROR_DATA,
    payload: data,
  };
};
