import * as ProductType from '../../constants/ActionTypes';

//Fetch product data from here
export const fetchProductData = () => {
  // console.log('action object')
  return {
    type: ProductType.FETCH_PRODUCT_DATA,
  };
};

export const fetchProductDataSuccess = productData => {
  return {
    type: ProductType.FETCH_PRODUCT_DATA_SUCCESS,
    payload: productData,
  };
};

export const fetchProfileDataFail = errorMsg => {
  return {
    type: ProductType.FETCH_PRODUCT_DATA_FAIL,
    payload: errorMsg,
  };
};

// Loader
export const productLoaderShow = () => ({
  type: ProductType.PRODUCT_SHOW_LOADER,
});

export const productLoaderHide = () => ({
  type: ProductType.PRODUCT_HIDE_LOADER,
});
