import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchPconfig, userAutoSignIn, setInitUrl} from '../redux/actions/Auth';
import {setThemeColor} from '../redux/actions/Setting';
import {getDefaultDomainDetails, mapHostNameToCode} from '../util/Extra';
import {isEmpty} from 'lodash';
import Error404 from '../components/Error404/Error404';
import CircularProgress from '@material-ui/core/CircularProgress';
class ConfigfromServer extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    const {clientConfigration, history} = this.props;
    const {serverConfig} = !!clientConfigration && clientConfigration;
    const componenRoute = sessionStorage.getItem('component_route');

    //client-Configration
    if (isEmpty(serverConfig)) {
      const domain = mapHostNameToCode(window.location.host);
      !isEmpty(domain) && this.props.setThemeColor(getDefaultDomainDetails().themeColor);
    }
    if (isEmpty(serverConfig)) {
      this.props.fetchPconfig({client_name: getDefaultDomainDetails().client_name});
    } else {
      this.props.setInitUrl(componenRoute);
      history.push(componenRoute);
    }
    // document.body.classList.remove('cdl-theme', 'argos-theme', 'contractor-theme');
    // document.body.classList.add(this.props.themeColor);
  }

  render() {
    const {serverConfig} = this.props;
    return (
      <React.Fragment>
        <div className="loaderForConfigrationPage">
          <CircularProgress />
        </div>
        {!isEmpty(serverConfig) && <Error404 />}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      userAutoSignIn,
      fetchPconfig,
      setThemeColor,
      setInitUrl,
    },
    dispatch,
  );
};

const mapStateToProps = ({auth, settings}) => {
  const {clientConfigration, configLoader, authUser, alertMessage, showMessage, initURL, userRole} = auth;
  const {themeColor} = settings;
  return {clientConfigration, configLoader, authUser, alertMessage, showMessage, initURL, themeColor, userRole};
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigfromServer);
