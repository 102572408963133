import {all, fork, put, delay, takeLatest} from 'redux-saga/effects';
import * as agetDetailsActions from 'constants/ActionTypes';
import {userSignOut} from '../actions/Auth';
import {clearError, showError} from '../actions/Error';
import {ToastManager} from '../../components/ToastManager/ToastManager';

function* errorDetails({payload}) {
  if (payload.error.response && payload.error.response.status === 401) {
    if (payload.page === '') {
      yield ToastManager.error(payload.error.response.data.message);
    } else {
      yield put(showError({message: payload.error.response.data.message, page: payload.page}));
    }
  } else if (payload.error.response && payload.error.response.status === 406) {
    if (payload.page === '') {
      yield ToastManager.error(payload.error.response.data.message);
    } else {
      yield put(showError({message: payload.error.response.data.message, page: payload.page}));
    }
    yield put(userSignOut());
  } else if (payload.error.response && payload.error.response.status === 500) {
    if (payload.page === '') {
      yield ToastManager.error(payload.error.response.data.message);
    } else {
      yield put(showError({message: payload.error.response.data.message, page: payload.page}));
    }
  } else {
    if (payload.error.response) {
      if (payload.page === '') {
        yield ToastManager.error(payload.error.response.data.message);
      } else {
        yield put(showError({message: payload.error.response.data.message, page: payload.page}));
      }
    } else {
      if (payload.page === '') {
        yield ToastManager.error(payload.error.message);
      } else {
        yield put(showError({message: payload.error.message, page: payload.page}));
      }
    }
  }
  yield delay(7000);
  yield put(clearError());
}

export function* errorDetailsWatcher() {
  yield takeLatest(agetDetailsActions.ERROR_DETAILS, errorDetails);
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([fork(errorDetailsWatcher)]);
}
