import {applyMiddleware, compose, createStore} from 'redux';
import reducers from '../reducers/index';
import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../saga';
import {persistedState, persistanceStateConfig} from '../persist';
import * as authActionType from '../../constants/ActionTypes';
const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();
const envType = (window.appConfig || {}).env || process.env.REACT_APP_ENV || process.env.NODE_ENV;
const middlewares = [sagaMiddleware, routeMiddleware];
const composeEnhancers = (envType !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export default function configureStore() {
  // on user signout needs to reset the redux store.
  const resetEnhancer = rootReducer => (state, action) => {
    if (action.type === authActionType.FLUSH_APP_DETAILS) {
      const newState = rootReducer(undefined, {});
      sessionStorage.clear();
      newState.router = state.router;
      return newState;
    }
    if (action.type !== authActionType.SIGNOUT_USER) return rootReducer(state, action);
    const newState = rootReducer(undefined, {});
    sessionStorage.removeItem('state');
    newState.router = state.router;
    return newState;
  };

  const store = createStore(resetEnhancer(reducers(history)), persistedState, composeEnhancers(applyMiddleware(...middlewares)));

  sagaMiddleware.run(rootSaga);

  // start listening to the store
  persistanceStateConfig(store);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers/index', () => {
      const nextRootReducer = require('../reducers/index');
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}
export {history};
