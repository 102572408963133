import React, {useState, useEffect} from 'react';
import './SignupToday.scss';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {email_regex} from '../../util/Extra';
import {bindActionCreators} from 'redux';
import {sendPrefilledSignupValue} from '../../redux/actions/Auth';
import {connect} from 'react-redux';
import SubmitLoader from 'components/SubmitLoader/SubmitLoader';
import CustomMaskedInput from 'components/CustomMaskedInput/CustomMaskedInput';
import {globalMessages} from 'util/Extra';

const useStyles = makeStyles(theme => ({
  error: {
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E05757',
    },
  },
  root: {
    '& .MuiFormLabel-root': {
      lineHeight: 1.5,
    },
  },
  inputRoot: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 4,
    },
  },
}));
const regex = {
  first_name: /^[a-zA-Z'/ /']{3,100}$/,
  email: email_regex,
  phone: /^[0-9 -]{14}$/,
  company: /^[a-zA-Z'/ /']{1,255}$/,
};

const helperText = {
  first_name: '',
  email: '',
  phone: '',
  company: '',
};

const displayName = {
  first_name: 'Name',
  email: 'Email',
  phone: 'Phone Number',
  company: 'Company',
};

const fieldType = {
  first_name: 'text',
  email: 'text',
  phone: 'text',
  company: 'text',
};

function SignupToday(props) {
  const classes = useStyles();
  const {loader, clientConfigration} = props;
  const {serverConfig} = !!clientConfigration && clientConfigration;
  const [createLead, setcreateLead] = useState({
    data: {
      first_name: '',
      email: '',
      phone: '',
      company: '',
    },
    error: {
      first_name: '',
      email: '',
      phone: '',
      company: '',
    },
  });

  //validate the fields
  const isValid = () => {
    let flag = true;
    const oldObj = createLead.error;

    for (const key in createLead.error) {
      if (createLead.data[key].trim() === '' && key !== 'company') {
        oldObj[key] = 'Required';
        flag = false;
      } else {
        if (
          createLead.data[key].trim() !== '' &&
          regex.hasOwnProperty(key) &&
          !regex[key].test(createLead.data[key].trim()) &&
          key !== 'name' &&
          key !== 'phone' &&
          key !== 'company'
        ) {
          oldObj[key] = 'Invalid ' + displayName[key];
          flag = false;
        } else if (regex.hasOwnProperty(key) && !regex[key].test(createLead.data[key].trim()) && key === 'name') {
          oldObj[key] = globalMessages.firstNameValidationErrorMessage;
          flag = false;
        } else if (createLead.data[key].trim() !== '' && regex.hasOwnProperty(key) && !regex[key].test(createLead.data[key].trim()) && key === 'company') {
          oldObj[key] = globalMessages.companyNameValidationErrorMessage;
          flag = false;
        } else if (regex.hasOwnProperty(key) && !regex[key].test(createLead.data[key].trim()) && key === 'phone') {
          oldObj[key] = globalMessages.phoneNumberValidationErrorMessage;
          flag = false;
        }
      }
    }

    setcreateLead(prevState => ({
      ...prevState,
      error: {
        ...oldObj,
      },
    }));
    return flag;
  };
  //once form is submitted check all the fields with required and corresponding regex
  const handleSubmit = async event => {
    const {history} = props;

    event.preventDefault();
    if (!isValid()) return;
    props.sendPrefilledSignupValue({from_source: 'amerikooler', ...createLead.data});
    sessionStorage.setItem('user_name', createLead.data.name);
    sessionStorage.setItem('email_id', createLead.data.email);
    sessionStorage.setItem('phone_number', createLead.data.phone);
    sessionStorage.setItem('company', createLead.data.company);
    sessionStorage.setItem('from_source', 'amerikooler');
    history.push(`/signup`);
  };

  //change the value of each input in state object
  const handleOnChange = event => {
    const createLeadTemp = createLead;
    createLeadTemp.data[event.target.name] = event.target.value;
    if (createLeadTemp.error[event.target.name] !== '') {
      createLeadTemp.error[event.target.name] = '';
    }

    setcreateLead(prevState => ({
      ...createLeadTemp,
    }));
  };

  //for updating the state on blur
  const handleOnBlur = event => {
    const createLeadTemp = createLead;
    createLeadTemp.data[event.target.name] = event.target.value;
    setcreateLead(prevState => ({
      ...prevState,
      data: {
        ...createLeadTemp.data,
      },
    }));
  };

  useEffect(() => {
    const {signinStatus, history, authUser, userRole} = props;
    if (authUser !== null && signinStatus && userRole !== null) {
      history.push(`/app`);
    }
  }, []);

  useEffect(() => {
    const {signinStatus, history, authUser, userRole} = props;
    if (authUser !== null && signinStatus && userRole !== null) {
      history.push(`/app`);
    }
  }, [props]);

  return (
    <div className="container-fluid signup-page main-contacts-wrapper">
      <div className="sub-wrapper">
        <div className="row">
          <div className="col-lg-12 text-center logoMain col-md-12">
            <img className="" alt="icon" src={serverConfig.institute_logo} />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="container contact-us-box">
              {/* {errorDisplay && pageName === 'SignUpToday' && (
              <div className="row form-error-wrap">
                <span className="col-md-6 col-lg-4 default-error">
                  <img src="../img/error-icon.svg"></img>
                  {errorMessage}
                </span>
              </div>
            )}  */}
              <br />
              <h2>SIGNUP TODAY</h2>
              {/* <h3>Join our growing team of certified installers</h3> */}
              <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  {Object.keys(createLead.data).map((key, idx) => {
                    if (fieldType[key] === 'text')
                      return (
                        <Grid xs={12} item key={key}>
                          <TextField
                            error={createLead.error[key] === '' ? false : true}
                            className={classes.error}
                            helperText={createLead.error[key] === '' ? helperText[key] : ''}
                            name={key}
                            label={`${displayName[key]} *`}
                            type={fieldType[key]}
                            value={createLead.data[key]}
                            variant="outlined"
                            margin="normal"
                            onChange={handleOnChange}
                            onBlur={handleOnBlur}
                            fullWidth
                            InputProps={{
                              inputProps: {
                                style: {
                                  padding: 13,
                                  backgroundColor: createLead.error[key] === '' ? '#FFFFFF' : '#FEF7F5',
                                },
                                ...(key === 'name' && {maxLength: 100}),
                                ...(key === 'email' && {maxLength: 255}),
                              },
                              ...(key === 'phone' && {inputComponent: CustomMaskedInput}),
                            }}
                          />
                          {createLead.error[key] !== '' && (
                            <p
                              className="Error"
                              style={{
                                color: '#E05757',
                                fontSize: '14px',
                                margin: '0px',
                              }}
                            >
                              <ErrorOutlineIcon
                                style={{
                                  fontSize: '14px',
                                  marginTop: '-4px',
                                  marginRight: '10px',
                                }}
                              />
                              {createLead.error[key]}
                            </p>
                          )}
                        </Grid>
                      );
                    else return <h1>No Field </h1>;
                  })}
                  <Grid xs={12} item>
                    <Button disabled={loader} variant="contained" type="submit" className="default-btn" color="primary" margin="normal" fullWidth>
                      GET CERTIFIED NOW {loader && <SubmitLoader />}
                    </Button>
                  </Grid>
                </Grid>
              </form>
              <div className="signup-link">
                Do you need an account? <a href="/signup">Signup</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      sendPrefilledSignupValue,
    },
    dispatch,
  );
};

const mapStateToProps = ({auth, error}) => {
  const {loader, authUser, signinStatus, userRole, clientConfigration, createLeadResponseData} = auth;
  const {errorDisplay, errorMessage, pageName} = error;
  return {
    loader,
    authUser,
    signinStatus,
    userRole,
    clientConfigration,
    errorDisplay,
    errorMessage,
    pageName,
    createLeadResponseData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupToday);
