import * as CertifiedInstallerType from '../../constants/ActionTypes';

//Fetch Certified Installer data
export const fetchCertifiedInstallerData = () => {
  return {
    type: CertifiedInstallerType.FETCH_CERTIFIED_INSTALLER_DATA,
  };
};

export const fetchCertifiedInstallerDataSuccess = certifiedInstallerData => {
  return {
    type: CertifiedInstallerType.FETCH_CERTIFIED_INSTALLER_DATA_SUCCESS,
    payload: certifiedInstallerData,
  };
};
// Fetch Certified Installer State list
export const fetchDropdownStateList = country => {
  return {
    type: CertifiedInstallerType.FETCH_DROPDOWN_STATE_LIST,
    payload: country,
  };
};

export const dropdownStateListSuccess = certifiedInstallerState => {
  return {
    type: CertifiedInstallerType.DROPDOWN_STATE_LIST_SUCCESS,
    payload: certifiedInstallerState,
  };
};
 //Send  footer email details  
export const sendFooterMail = data => {
  return {
    type: CertifiedInstallerType.SEND_FOOTER_MAIL,
    payload: data,
  };

};

// Send footer email response flag
export const sendMailFromFooterFlag = flag => ({
  type: CertifiedInstallerType.SEND_MAIL_FROM_FOOTER_FLAG,
  payload: flag,
});

// Loader
export const certifiedInstallerShowLoader = () => ({
  type: CertifiedInstallerType.CERTIFIED_INSTALLER_SHOW_LOADER,
});

export const certifiedInstallerHideLoader = () => ({
  type: CertifiedInstallerType.CERTIFIED_INSTALLER_HIDE_LOADER,
});
