import * as types from '../../constants/ActionTypes';

const INIT_STATE = {
  errorDisplay: false,
  errorMessage: null,
  pageName: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.ADD_ERRORR: {
      return {
        ...state,
        errorMessage: action.payload.message,
        errorDisplay: true,
        pageName: action.payload.page,
      };
    }

    case types.CLEAR_ERROR: {
      return {
        ...state,
        errorMessage: null,
        errorDisplay: false,
        pageName: '',
      };
    }

    default:
      return state;
  }
};
