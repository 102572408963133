import * as CourseType from '../../constants/ActionTypes';
import {all, fork, put, takeLatest} from 'redux-saga/effects';
import {profileLoaderHide, profileLoaderShow} from '../actions/Profile';
import {errorHandler} from '../actions/Error';
import {mainAxios} from 'util/Api';
import {fetchProfileDataSuccess} from '../actions/Profile';

// profile details fetch worker
export function* profileContent() {
  try {
    yield put(profileLoaderShow());
    const profileResponse = yield mainAxios.get(`/my-profile`);
    if (profileResponse.data) {
      yield put(fetchProfileDataSuccess(profileResponse.data));
    }
  } catch (error) {
    if (!error.response) {
      yield put(errorHandler({error: error, page: ''}));
    }
  }
  yield put(profileLoaderHide());
}

// course details fetch watcher
export function* fetchProfileDataWatcher() {
  yield takeLatest(CourseType.FETCH_PROFILE_DATA, profileContent);
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([fork(fetchProfileDataWatcher)]);
}
