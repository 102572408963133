import React, {Component} from 'react';
import {encryptData, decryptData} from '../util/Crypto';

export default function securityFilter(HocComponent, data) {

  return class SecurityFilter extends Component {
    
    redirectToLMS = () => {
      const {userId, profileData } = this.props;
      const {first_name, last_name, email, city, zip, phone } = !!profileData && profileData; 
      let adminRedirectURL = encryptData(`usr_email=${email}&usr_fname=${first_name}&usr_lname=${last_name}&usr_city=${city}&usr_phone=${phone}&usr_zip=${zip}&usr_id=${userId}&apikey=${process.env.REACT_APP_AMERIKOOLER_API_KEY}`);
      window.sessionStorage.clear();
      // console.log(`usr_email=${email}&usr_fname=${first_name}&usr_lname=${last_name}&usr_city=${city}&usr_phone=${phone}&usr_zip=${zip}&usr_id=${userId}&apikey=${process.env.REACT_APP_AMERIKOOLER_API_KEY}`)
      window.location.replace(`${'https://lms2-ak.higherl.com/amerikooler_login_validate.php'}?${adminRedirectURL}`)
    }
    
    render() {
      const {location, authUser, purchasedCourse, userIsAdmin, userId, userTypeId, token, clientConfigration, profileData } = this.props;
      const {serverConfig} = !!clientConfigration && clientConfigration;
      // const usrToken = !!token ? token : sessionStorage.getItem('Authorization');
      return purchasedCourse && purchasedCourse && location.pathname !== '/logout' && location.pathname !== '/configration' ? (
        <>{window.location.replace(`${serverConfig.institute_lms_url}/ext/login/${authUser}/${userId}/${userTypeId}/${token}/${new Date().getTime()}`)}</>
      ) : userIsAdmin && location.pathname !== '/logout' && location.pathname !== '/configration' ? <>{this.redirectToLMS()}</> : (
        <HocComponent {...this.props} />
      );
    }
  };
}

