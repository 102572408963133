//Error component if page not found

import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
// import {bugsnagWarn} from '../../util/bugsnagUtils';
// import {DeadEndError} from '../../util/errorUtils';

const Error404 = () => {
  useEffect(() => {
    // Update Error on Bugsnag.
    // bugsnagWarn(new DeadEndError('Warning: User reached to the error page.'), '404 Page Not Found');
  });

  return (
    <div className="app-wrapper page-error-container animated slideInUpTiny animation-duration-3">
      <div className="page-error-content">
        <div className="error-code mb-2 animated zoomInDown">404</div>
        <h2 className="text-center fw-regular title bounceIn animation-delay-10 animated"> Page Not found</h2>
        <h4 className="text-center"> The page you are looking for is not found. Please, make sure you have typed the correct URL.</h4>

        <p className="text-center zoomIn animation-delay-20 animated">
          <Link className="btn btn-primary" to="/signin">
            Go to Home Page
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Error404;
