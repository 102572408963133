import React, {Component} from 'react';
import './SignupGreeting.scss';
import {RiErrorWarningLine} from 'react-icons/ri';
import GreetingHeader from 'components/GreetingHeader/GreetingHeader';
import {connect} from 'react-redux';
import {resendEmailVerificationLink} from 'redux/actions/Auth';
import {bindActionCreators} from 'redux';
import SubmitLoader from 'components/SubmitLoader/SubmitLoader';
import {isEmpty} from 'lodash';

class SignupGreeting extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      email: '',
    };
  }

  componentDidMount() {
    const {history, emailVerificationUserErrorData} = this.props;

    if (!isEmpty(emailVerificationUserErrorData)) {
      this.setState({
        email: !!emailVerificationUserErrorData ? emailVerificationUserErrorData.email : '',
      });
    } else {
      history.push(`/signin`);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {history, emailVerificationUserErrorData} = nextProps;
    if (!!emailVerificationUserErrorData) {
      return null;
    } else {
      history.push(`/signin`);
    }
    return null;
  }

  render() {
    const {email} = this.state;
    const {loader, emailVerificationUserErrorData, errorDisplay, errorMessage, pageName, history} = this.props;
    return (
      <React.Fragment>
        <div className="app-container pre-signupGreeting-wrapper">
          <div className="app-main-container">
            <GreetingHeader />
            <div className="panel deep-purple"></div>
            <main className="freeBird">
              <div className="container d-flex flex-column justify-content-center vh-100 ">
                <div className="row justify-content-center">
                  <div className="col-md-7  alert-box-wrap">
                    {errorDisplay && pageName === 'SignupGreeting' && (
                      <div className="row form-error-wrap warning-messge">
                        <div className="col-12">
                          <span className="default-error mx-0">
                            <RiErrorWarningLine className="warningIcon" />
                            {errorMessage}
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="card">
                      <div className="card-content">
                        <div className="parent-congrats-icon">
                          <div className="congrats-icon">
                            <img src="../img/Vector.svg" alt="success" />
                          </div>
                        </div>

                        <div className="heading">
                          <p className="cong-p">CONGRATULATIONS</p>
                          <span> You're almost done signing up for your Amerikooler Account.</span>
                        </div>
                        <hr />
                        <div className="body-content">
                          <div className="box-content alert-box-content">
                            <p className="text-first-block">
                              An email verification link has been sent to your email address: <a href={`mailto:${email}`}>{email}</a>{' '}
                            </p>
                            <p className="text-block">Please click the link in your email to complete your verification and log in.</p>
                            <p className="text-last-block">
                              If you did not receive the verification email, double check that it didn't end up in your spam or junk folder, or click below to
                              resend.
                            </p>
                          </div>
                        </div>
                        <div className="submit-btn">
                          <button
                            type="button"
                            className="default-btn"
                            disabled={loader}
                            onClick={() => {
                              this.props.resendEmailVerificationLink(emailVerificationUserErrorData);
                            }}
                          >
                            Resend Verification Email {loader && <SubmitLoader />}
                          </button>
                          <button
                            type="button"
                            className="ml-2 default-btn"
                            disabled={loader}
                            onClick={() => {
                              history.push('/signin');
                            }}
                          >
                            Go to Login
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{position: 'absolute', left: '0px', bottom: '0px'}} className="hideOnMobile">
                <img className="sideHeaderImage" alt="icon" src={process.env.PUBLIC_URL + '../../../../img/side-footer.svg'} />
              </div>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      resendEmailVerificationLink,
    },
    dispatch,
  );
};

const mapStateToProps = ({auth, error}) => {
  const {emailVerifiedStatus, loader, authUser, emailVerificationUserErrorData} = auth;
  const {errorDisplay, errorMessage, pageName} = error;

  return {
    loader,
    emailVerifiedStatus,
    authUser,
    emailVerificationUserErrorData,
    errorDisplay,
    errorMessage,
    pageName,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupGreeting);
