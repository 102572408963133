import * as ProductType from '../../constants/ActionTypes';
import {all, fork, put, takeEvery} from 'redux-saga/effects';
import { mainAxios} from 'util/Api';
import {productLoaderHide, productLoaderShow, fetchProductDataSuccess} from '../actions/Product';
import {errorHandler} from '../actions/Error';
import config from '../../config/config';

// product details fetch worker
export function* productContentSaga() {
  try {
    yield put(productLoaderShow());
    const productResponse = yield mainAxios.get(`/products/${config.amerikooler.institute_Id}`);

    if (productResponse.data) {
      yield put(fetchProductDataSuccess(productResponse.data));
    }
  } catch (error) {
    if (!error.response) {
      yield put(errorHandler({error: error, page: ''}));
    }
  }
  yield put(productLoaderHide());
}

// Product details fetch watcher
export function* fetchProductDataWatcher() {
  // console.log('fetchProductDataWatcher');
  yield takeEvery(ProductType.FETCH_PRODUCT_DATA, productContentSaga);
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([fork(fetchProductDataWatcher)]);
}
