import {all} from 'redux-saga/effects';
import authSagas from './Auth';
import errorDetails from './Error';
import profileDetails from './Profile';
import productDetails from './Product';
import cartDetails from './Cart';
import CertifiedInstaller from './CertifiedInstaller';

export default function* rootSaga() {
  yield all([authSagas(), errorDetails(), profileDetails(), productDetails(), cartDetails(), CertifiedInstaller()]);
}
