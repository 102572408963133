//App component for index page

import React, {Component} from 'react';
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import 'assets/vendors/style';
import defaultTheme from './themes/defaultTheme';
// import EcomApp from './ecommerce/index';
import SignupGreeting from './SignupGreeting/SignupGreeting';
import MainApp from 'app/index';
import {setInitUrl} from '../redux/actions/Auth';
import RTL from 'util/RTL';
import asyncComponent from 'util/asyncComponent';
import SignIn from './SignIn';
import ChangePassword from './ChangePassword/ChangePassword';
// import LandingPage from './LandingPage/LandingPage';
import SignupToday from './SignupToday/SignupToday';
import Registration from '../components/Registration/Registration';
import CertifiedInstaller from './CertifiedInstaller/CertifiedInstaller';
import ForgotPassword from './ForgotPassword';
import {bindActionCreators} from 'redux';
import {isEmpty} from 'lodash';
import Helmet from 'react-helmet';
import CircularProgress from '@material-ui/core/CircularProgress';
import ConfigfromServer from './ConfigfromServer';
import {clearError} from '../redux/actions/Error';
import Logout from './Logout';
import securityFilter from '../security';
import EmailVerification from './EmailVerification/EmailVerification';
import ThankyouCreateLeadsPopup from './ThankyouCreateLeadsPopup/ThankyouCreateLeadsPopup';
import ResetPasswordError from './ResetPasswordError/ResetPasswordError';
import ToastContainer from '../components/ToastManager/ToastManager';
import useExternalScript from "../components/Hooks/useExternalScript";
import config from '../config/config';

const RestrictedRoute = ({component: Component, authUser, serverConfig, ...rest}) => {
  return (
    <Route
      {...rest}
      render={props =>
        authUser && serverConfig ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: {
                from: props.location.pathname,
              },
            }}
          />
        )
      }
    />
  );
};

const ServerConfigRoute = ({component: Component, configLoader, serverConfig, path, location, ...rest}) => {
  sessionStorage.setItem('component_route', location.pathname + location.search);
  useExternalScript(config.amerikooler.paymentGatewayUrl);
  return (
    <Route
      {...rest}
      render={props =>
        !isEmpty(serverConfig) ? (
          <Component {...props} />
        ) : (
          !configLoader && (
            <Redirect
              to={{
                pathname: '/configration',
                state: {
                  from: props.location.pathname,
                },
              }}
            />
          )
        )
      }
    />
  );
};

class App extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    document.body.classList.remove('amerikooler-theme');
    document.body.classList.add(this.props.themeColor);
  }

  componentDidMount() {
    this.props.clearError();
  }
  UNSAFE_componentWillMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === '') {
      //this.props.history.location.pathname  = get last url(/,/pre-signup/paymentdetails,/pre-signup/paymentsuccessful)
      this.props.setInitUrl(this.props.history.location.pathname);
    }
  }

  // Get page meta details for favicon, title and description
  getPageMetaDetails = () => {
    const {serverConfig} = !!this.props.clientConfigration && this.props.clientConfigration;
    if (serverConfig) {
      return !isEmpty(serverConfig.meta_details) && serverConfig.meta_details;
    }
    return false;
  };

  render() {
    const {configLoader, match, location, authUser, initURL, isDirectionRTL, clientConfigration, purchasedCourse} = this.props;
    const {serverConfig} = !!clientConfigration && clientConfigration;
    if (location.pathname === '/') {
      if (isEmpty(serverConfig)) {
        return <Redirect to={'/configration'} />;
      } else if ((initURL === '' || initURL === '/') && authUser !== null && purchasedCourse === false) {
        return <Redirect to={`/app`} />;
      } else if ((initURL === '' || initURL === '/' || initURL === 'signin') && authUser === null && purchasedCourse === null) {
        return <Redirect to={`/signin`} />;
      } else {
        return <Redirect to={initURL} />;
      }
    }

    const applyTheme = createTheme(defaultTheme);
    // change theme according to client
    document.body.classList.remove('amerikooler-theme');
    document.body.classList.add(this.props.themeColor);

    if (isDirectionRTL) {
      applyTheme.direction = 'rtl';
      document.body.classList.add('rtl');
    } else {
      document.body.classList.remove('rtl');
      applyTheme.direction = 'ltr';
    }
    return (
      <MuiThemeProvider theme={applyTheme}>
        {!configLoader && (
          <Helmet>
            <title>{this.getPageMetaDetails().institute_meta_title}</title>
            <meta name="description" content={this.getPageMetaDetails().institute_meta_description} />
            <link href={this.getPageMetaDetails().institute_meta_favicon} rel="shortcut icon" sizes="32x32" />
          </Helmet>
        )}
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <RTL>
            <div className="app-main">
              <div className="loader">
                <div className="text">
                  <CircularProgress color="inherit" />
                </div>
              </div>
              <Switch>
                {/* <RestrictedRoute path={`${match.url}app`} authUser={authUser} component={MainApp} /> */}
                <RestrictedRoute path={`${match.url}app`} authUser={authUser} serverConfig={serverConfig} configLoader={configLoader} component={MainApp} />
                {/* <ServerConfigRoute path={`${match.url}ecommerce`} serverConfig={serverConfig} configLoader={configLoader} component={EcomApp} /> */}
                {/* <ServerConfigRoute path="/home" serverConfig={serverConfig} configLoader={configLoader} component={LandingPage} /> */}
                <ServerConfigRoute path="/thankyou" serverConfig={serverConfig} configLoader={configLoader} component={ThankyouCreateLeadsPopup} />
                <ServerConfigRoute path="/signin" serverConfig={serverConfig} configLoader={configLoader} component={SignIn} />
                <ServerConfigRoute path="/verify-user" serverConfig={serverConfig} configLoader={configLoader} component={EmailVerification} />
                <ServerConfigRoute path="/password/reset/:authtoken" serverConfig={serverConfig} configLoader={configLoader} component={ChangePassword} />
                {!authUser && (
                  <ServerConfigRoute path="/certifiedinstaller" serverConfig={serverConfig} configLoader={configLoader} component={CertifiedInstaller} />
                )}
                <ServerConfigRoute path="/contactus" serverConfig={serverConfig} configLoader={configLoader} component={SignupToday} />
                <ServerConfigRoute path="/signup" serverConfig={serverConfig} configLoader={configLoader} component={Registration} />
                <ServerConfigRoute path="/signupgreeting" serverConfig={serverConfig} configLoader={configLoader} component={SignupGreeting} />
                {/* <ServerConfigRoute path="/emailverification" serverConfig={serverConfig} configLoader={configLoader} component={EmailVerification} /> */}
                <Route path="/logout" component={Logout} />
                <ServerConfigRoute path="/forgot-password" serverConfig={serverConfig} configLoader={configLoader} component={ForgotPassword} />
                <ServerConfigRoute path="/resetpassworderror" serverConfig={serverConfig} configLoader={configLoader} component={ResetPasswordError} />

                <Route path="/configration" component={ConfigfromServer} />
                <Route component={asyncComponent(() => import('components/Error404/Error404'))} />
              </Switch>
            </div>
            {/* <NotificationContainer /> */}
            <ToastContainer />
          </RTL>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setInitUrl,
      clearError,
    },
    dispatch,
  );
};

const mapStateToProps = ({settings, auth, profile}) => {
  const {themeColor, sideNavColor, locale, isDirectionRTL} = settings;
  const {loader, configLoader, clientConfigration, authUser, initURL, signinStatus, userRole, purchasedCourse, userId, userTypeId, token, userIsAdmin} = auth;
  const {profileData} = profile;
  return {
    loader,
    configLoader,
    clientConfigration,
    profileData,
    themeColor,
    sideNavColor,
    locale,
    isDirectionRTL,
    authUser,
    initURL,
    signinStatus,
    userRole,
    userId,
    userIsAdmin,
    userTypeId,
    purchasedCourse,
    token,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(securityFilter(App));
