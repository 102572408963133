import React from 'react';
import {connect} from 'react-redux';
import './PaymentSuccess.scss';
import {fetchCartData, removeFromCart, createOrder} from '../../../redux/actions/Cart';
import {bindActionCreators} from 'redux';
import {addToCart} from '../../../redux/actions/Cart';
import {hasCourseResponse} from '../../../redux/actions/Auth';

const PaymentSucess = ({orders, authUser, userId, userTypeId, name, token, clientConfigration, hasCourseResponse}) => {
  const {serverConfig} = !!clientConfigration && clientConfigration;
  return (
    <div className="cartContainer">
      <div className="container2 order">
        <div className="d-flex justify-content-center">
          <div className="success-circle">
            <img src="../img/success-icon.png" alt="success" />
          </div>
        </div>
        <br />
        <br />
        <h2 className="heading">THANK YOU</h2>
        <div className="row">
          <div className="col-lg-6">
            <p className="heading2">You have now successfully registered for the course</p>
          </div>
          <div className="col-lg-6">
            <p className="heading3">
            Order No: <span className="order-no">{orders&&orders.order_id}</span>
            </p>
          </div>
        </div>
        <div className="course-order-box-main">
          <div>
            <div className="course-order-box">
              <img
                // src={process.env.PUBLIC_URL + '../../../../img/installation-course-horizontal.png'}
                src={orders && orders.course_image}
                // src={'https://s3.amazonaws.com/labpub_repos/lms_test/client_files/21/2/assets/images/amerikooler-prod-thumbnail.png'}
                alt={'Product_image'}
                style={{maxWidth: '142px'}}
              ></img>
              {/* <img src="../img/product.png" alt={'Product_image'} style={{maxWidth: '142px', maxHeight: '95px'}}></img> */}
              <div className="course-box-heading">
                <span className="heading1">{orders && orders.section_name}</span>
                <span className="heading2">{`By: ${orders && orders.academy_name}`}</span>
                <span className="heading2"></span>
              </div>
            </div>

            <div className="d-flex justify-content-md-end justify-content-xs-center">
              <button
                className="course-start-btn"
                onClick={() => {
                  hasCourseResponse(true);
                  return (window.location.href = `${
                    serverConfig.institute_lms_url
                  }/ext/login/${authUser}/${userId}/${userTypeId}/${token}/${new Date().getTime()}`);
                }}
              >
                Start Your Course
              </button>
            </div>
          </div>
        </div>

        {/* <div className="d-flex justify-content-md-start justify-content-xs-center">
          <div className="order-footer-heading">Thank you for making this purchase!</div>
        </div> */}
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchCartData,
      removeFromCart,
      createOrder,
      addToCart,
      hasCourseResponse,
    },
    dispatch,
  );
};

const mapStateToProps = ({cart, auth}) => {
  const {cartLoader, orders} = cart;
  const {authUser, userId, userTypeId, name, token, clientConfigration, purchasedCourse} = auth;
  return {
    cartLoader,
    orders,
    authUser,
    userId,
    userTypeId,
    name,
    token,
    clientConfigration,
    purchasedCourse,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSucess);
