import {all, fork, put, takeEvery, delay, select, takeLatest} from 'redux-saga/effects';
import {mainAxios} from 'util/Api';
import {push} from 'connected-react-router';
import * as authType from 'constants/ActionTypes';
import {
  setPconfig,
  userSignInSuccess,
  userSignOutSuccess,
  authLoaderHide,
  authLoaderShow,
  showRedirectLinkLoader,
  hideRedirectLinkLoader,
  setConfigLoader,
  setSupervisorId,
  setPasswordUpdateLoader,
  changePasswordCompleted,
  hasCourseResponse,
  setUserIsAdmin,
  dropdownCityListSuccess,
  cityStateDataByZipSuccess,
  sendEmailVerification,
  signupUserEmailVerified,
  emailVerificationStatusCode,
  saveCreateLeadRespondeData,
  showResendLinkLoader,
  hideResendLinkLoader,
} from '../actions/Auth';
import {errorHandler} from '../actions/Error';
import {ToastManager} from '../../components/ToastManager/ToastManager';
import {isEmpty, omit} from 'lodash';
import {fetchProfileDataSuccess} from '../actions/Profile';

//fetch login api
function* signInUser({payload: {loginDetails}}) {

  try {
    yield put(authLoaderShow());
    yield put(showRedirectLinkLoader());
    const sentFormData = {
      ...loginDetails,
      password: loginDetails.password,
      encrypted_password: 0,
    };
    const userDetails = yield mainAxios.post('/login', sentFormData);
    if (userDetails.data) {
      if (userDetails.status === 200) {
        const details = userDetails.data;
        sessionStorage.setItem('Authorization', details.jwt);
        sessionStorage.setItem('user_id', details.emailId);
        mainAxios.defaults.headers.common['Authorization'] = 'Bearer ' + details.jwt;
        const studentCourse = yield mainAxios.get(`/student/has-courses`);
        const myProfile = yield mainAxios.get(`/my-profile`);
        if (myProfile.data) {
          yield put(fetchProfileDataSuccess(myProfile.data));
        }

        if(details.userTypeId === 1){
          yield put(setUserIsAdmin(true));
        }else{
          yield put(setUserIsAdmin(false));
        }

        yield put(
          userSignInSuccess({
            authUser: details.emailId,
            userId: details.userId,
            userTypeId: details.userTypeId,
            token: details.jwt,
            userRole: 'learner',
          }),
        );
        yield put(
          setSupervisorId({
            userId: details.userId,
          }),
        );
        yield put(hasCourseResponse(studentCourse.data.has_courses));
      }
    }
  } catch (error) {
    yield put(errorHandler({error: error, page: 'SignIn'}));
  }

  yield put(hideRedirectLinkLoader());
  yield put(authLoaderHide());
}

export function* signInUserWatcher() {
  yield takeEvery(authType.SIGNIN_USER, signInUser);
}

//fetch Register api
function* signUpUser({payload: {registerData, recaptchaToken, createLeadResponseData}}) {
  let mutetedObj = {
    ...registerData,
    email: registerData.email.toLowerCase(),
  };
  try {
    yield put(authLoaderShow());
    const sentFormData = {
      ...mutetedObj,
      ...(!!createLeadResponseData && {lead_id: createLeadResponseData.lead_id}),
      send_email: 1,
    };
    const myBoolean = false;
    // const myBoolean = true;
    let verifyingCaptcha;
    if (myBoolean) {
      verifyingCaptcha = yield mainAxios.post('/verify-captcha', {token: recaptchaToken});
    }
    if (myBoolean === false || verifyingCaptcha.data) {
      const userDetails = yield mainAxios.post('/register-user-by-himself', sentFormData);
      if (userDetails.status === 200) {
        const details = userDetails.data;
        sessionStorage.setItem('Authorization', details.jwt);
        sessionStorage.setItem('user_id', details.emailId);
        mainAxios.defaults.headers.common['Authorization'] = 'Bearer ' + details.jwt;
        const myProfile = yield mainAxios.get(`/my-profile`);
        
        if (myProfile.data) {
          // console.log(myProfile)
          yield put(fetchProfileDataSuccess(myProfile.data));
        }

        yield put(
          userSignInSuccess({
            authUser: details.emailId,
            userId: details.userId,
            userTypeId: details.userTypeId,
            token: details.jwt,
          }),
        );

        yield put(push('/app'));
      }
    } else {
      ToastManager.error('Invalid Captcha!');
    }
  } catch (error) {
    yield put(errorHandler({error: error, page: 'SignUp'}));
  } finally {
    yield put(authLoaderHide());
  }
}

export function* signUpUserWatcher() {
  yield takeEvery(authType.SIGNUP_USER, signUpUser);
}

//fetch institute-configuration api
function* fetchPartnerConfigDetails() {
  try {
    yield put(setConfigLoader(true));
    const redirectPath =
      !!sessionStorage.getItem('component_route') && sessionStorage.getItem('component_route') !== '' ? sessionStorage.getItem('component_route') : '/';
    // const configData = yield mainAxios.get(`/client-configuration`);
    const configData = yield mainAxios.get(`/institute-configuration`);
    if (!isEmpty(configData.data) && configData.status === 200) {
      const data = configData.data;
      yield put(setPconfig(data));
    }
    yield put(push(redirectPath));
  } catch (error) {
    yield put(errorHandler({error: error, page: ''}));
  } finally {
    yield put(setConfigLoader(false));
  }
}

export function* fetchPconfigDetailsWatcher() {
  yield takeEvery(authType.FETCH_PCONFIG, fetchPartnerConfigDetails);
}

//fetch logout api
function* signOutUser() {
  try {
    const logoutDetails = yield mainAxios.get('/logout');
    sessionStorage.removeItem('component_route');
    yield put(push('/'));
    if (logoutDetails.status === 200) {
      sessionStorage.removeItem('jsat_token');
      sessionStorage.removeItem('Authorization');
      sessionStorage.removeItem('user_id');
      sessionStorage.removeItem('state');
      sessionStorage.removeItem('name1');
      sessionStorage.removeItem('name2');
      sessionStorage.removeItem('email_id');
      sessionStorage.removeItem('phone_number');
      sessionStorage.removeItem('company');
      delete mainAxios.defaults.headers.common['Authorization'];
      yield put(userSignOutSuccess());
    }
  } catch (error) {
    if (!error.response) {
      yield put(errorHandler({error: error, page: ''}));
    }
  }
}

export function* signOutWatcher() {
  yield takeEvery(authType.SIGNOUT_USER, signOutUser);
}

//fetch forgotPassword api
function* forgotPassword({payload: {email, recaptchaToken}}) {
  try {
    yield put(authLoaderShow());
    const formData = {
      email,
    };
    const myBoolean = false;
    // const myBoolean = true;
    let verifyingCaptcha;
    if (myBoolean) {
      verifyingCaptcha = yield mainAxios.post('/verify-captcha', {token: recaptchaToken});
    }
    if (myBoolean === false || verifyingCaptcha.data) {
      const response = yield mainAxios.post('/forgot-password-request', formData);
      if (response.data) {
        // ToastManager.success(response.data.message);
        ToastManager.success('Your Reset Password link has been sent to your email successfully',null, 5000);

        yield delay(1000);
        yield put(push('/signin'));
      }
    }
  } catch (error) {
    yield put(errorHandler({error: error, page: 'ForgetPassword'}));
  } finally {
    yield put(authLoaderHide());
  }
}

export function* forgotPasswordWatcher() {
  yield takeEvery(authType.FORGOT_PASSWORD_START, forgotPassword);
}

// update password by email link url
function* sendUpdatePassword({payload}) {
  yield put(setPasswordUpdateLoader(true));
  const reduxState = yield select();
  const {auth} = reduxState;
  const {passwordTokenDetails, updateDefaultPassword} = auth;
  const {token, email} = passwordTokenDetails;

  try {
    // send updated password data
    const response = yield mainAxios.post('/reset-password', {
      token: token,
      email: email,
      ...payload,
    });

    // provide user feedback related to updated password
    if (response.status === 200 && response.data) {
      yield put(changePasswordCompleted(true));
      // ToastManager.success(response.data.message);
      ToastManager.success('Your Password has been changed Successfully !',null, 5000);
      yield put(push('/signin'));

      if (updateDefaultPassword) {
        yield put(userSignInSuccess({updateDefaultPassword: false}));
      }
    }
    // check response
  } catch (error) {
    yield put(errorHandler({error: error, page: 'changepassword'}));
  }
  yield put(setPasswordUpdateLoader(false));
}

export function* updatePasswordWatcher() {
  yield takeEvery(authType.CHANGE_PASSWORD, sendUpdatePassword);
}

// fetch city drodown List
export function* fetchDropdownCityList({payload}) {
  try {
    const state = payload;
    const cityListResponse = yield mainAxios.get(`/get-cities/US/${state}`);

    if (cityListResponse.data) {
      yield put(dropdownCityListSuccess(cityListResponse.data));
    }
  } catch (error) {
    if (!error.response) {
      yield put(errorHandler({error: error, page: ''}));
    }
  }
}

//  Dropdown city list fetch watcher
export function* fetchDropdownCityListWatcher() {
  yield takeLatest(authType.FETCH_DROPDOWN_CITY_LIST, fetchDropdownCityList);
}

// fetch city state by Zipcode
export function* fetchCityStateNameByZipcode({payload}) {
  try {
    const zip = payload;
    const cityStateData = yield mainAxios.get(`/get-city-state-by-zipcode/${zip}`);

    if (cityStateData.data) {
      yield put(cityStateDataByZipSuccess(cityStateData.data));
    }
  } catch (error) {
    yield put(cityStateDataByZipSuccess(null));

    if (!error.response) {
      yield put(errorHandler({error: error, page: ''}));
    }
  }
}

//  city state by Zipcode fetch watcher
export function* fetchCityStateByZipWatcher() {
  yield takeLatest(authType.FETCH_CITY_STATE_BY_ZIP, fetchCityStateNameByZipcode);
}

// email verification api
export function* emailVerificationFunction({payload}) {
  try {
    yield put(authLoaderShow());
    const emailVerification = yield mainAxios.post(`/verify-user`, payload);

    if (emailVerification.data) {
      yield put(sendEmailVerification(emailVerification.data));
      ToastManager.success(emailVerification.data);
      yield put(signupUserEmailVerified(false));
      // console.log('emailVerification', emailVerification);
      yield put(emailVerificationStatusCode({code: 200, message: ''}));
      yield put(authLoaderHide());
    }
  } catch (error) {
    yield put(authLoaderHide());
    yield put(signupUserEmailVerified(true));
    yield put(emailVerificationStatusCode({code: error.response.status, message: error.response.data.message}));
    if (!error.response) {
      yield put(errorHandler({error: error, page: ''}));
    }
  }
}

//  email verification  watcher
export function* emailVerificationWatcher() {
  yield takeLatest(authType.EMAIL_VERIFICATION, emailVerificationFunction);
}

// resend link for email verification
export function* resendEmailVerificationLinkFunction({payload}) {
  try {
    yield put(showResendLinkLoader());
    const resendEmailVerification = yield mainAxios.post(`/email/resend-email-verification-notification`, payload);
    if (resendEmailVerification.data) {
      ToastManager.success(resendEmailVerification.data, null, 6000);
      yield put(signupUserEmailVerified(false));
      yield put(hideResendLinkLoader());
    }
  } catch (error) {
    yield put(signupUserEmailVerified(true));
    yield put(hideResendLinkLoader());
    if (!error.response) {
      yield put(errorHandler({error: error, page: ''}));
    }
  }
}

//  resend link for email verification watcher
export function* resendEmailVerificationLinkWatcher() {
  yield takeLatest(authType.RESEND_EMAIL_VERIFICATION_LINK, resendEmailVerificationLinkFunction);
}

//  send prefilled signup data to server
export function* sendPrefilledsignupDataFunction({payload}) {
  const sendDataToServer = omit(payload, 'callingPage');

  yield put(authLoaderShow());
  try {
    const sendPrefilledsignupData = yield mainAxios.post(`/create-lead`, sendDataToServer);

    if (sendPrefilledsignupData.data) {
      // if (payload.callingPage === 'signup') {
      //   sendPrefilledsignupData.data.existing_lead
      //     ? ToastManager.success('We already have your submission, Please fill all the details to complete your registration process.')
      //     : ToastManager.success('Please fill all the details to complete your registration process.');
      // }
      yield put(saveCreateLeadRespondeData(sendPrefilledsignupData.data));
      yield put(authLoaderHide());
    }
  } catch (error) {
    ToastManager.error(error.response.data.message);
    if (!error.response) {
      yield put(errorHandler({error: error, page: ''}));
    }
    yield put(authLoaderHide());
  }
}

//  send prefilled signup data to server watcher
export function* sendPrefilledsignupDataFunctionWatcher() {
  yield takeLatest(authType.SEND_PREFILLED_SIGNUP_VALUE, sendPrefilledsignupDataFunction);
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([
    fork(signInUserWatcher),
    fork(signUpUserWatcher),
    fork(updatePasswordWatcher),
    fork(forgotPasswordWatcher),
    fork(signOutWatcher),
    fork(fetchPconfigDetailsWatcher),
    fork(fetchDropdownCityListWatcher),
    fork(fetchCityStateByZipWatcher),
    fork(emailVerificationWatcher),
    fork(resendEmailVerificationLinkWatcher),
    fork(sendPrefilledsignupDataFunctionWatcher),
  ]);
  // For fetch if no auth header set then get token from storage
  if (!mainAxios.defaults.headers.common['Authorization'] && sessionStorage.getItem('Authorization') != null) {
    mainAxios.defaults.headers.common['Authorization'] = 'Bearer ' + sessionStorage.getItem('Authorization');
  }
}
