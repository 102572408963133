import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import {bindActionCreators} from 'redux';
import {userSignIn, hideRedirectLinkLoader} from '../redux/actions/Auth';
import {connect} from 'react-redux';
import {isEmpty} from 'lodash';
import {email_regex, password_regex, white_space} from '../util/Extra';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {withStyles} from '@material-ui/core/styles';
import SubmitLoader from 'components/SubmitLoader/SubmitLoader';

const styles = theme => ({
  formWrapper: {
    '& .MuiInputLabel-formControl': {
      top: '6% !important',
    },
  },
  error: {
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E05757',
    },
  },
});

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginData: {
        email: '',
        password: '',
      },
      loginDataError: {
        emailError: '',
        passwordError: '',
      },
    };
  }

  //set current login details in state
  handleChange = e => {
    if (!isEmpty(e.target.value) && !white_space.test(e.target.value) && e.target.name === 'password') {
      return;
    }
    const {loginData} = {
      ...this.state,
    };

    const {loginDataError} = {
      ...this.state,
    };

    loginData[e.target.name] = e.target.value;

    // if error is their then empty the error text to remove error msg
    if (loginDataError[e.target.name + 'Error'] !== '') {
      loginDataError[e.target.name + 'Error'] = '';
    }

    this.setState(prevState => ({
      ...prevState,
      loginDataError: {
        ...loginDataError,
      },
      loginData: {
        ...loginData,
      },
    }));
  };
  //remove extra spaces
  handleChangeOnBlur = e => {
    const {loginData} = {...this.state};
    if (e.target.name === 'email') {
      loginData[e.target.name] = e.target.value.trim();
    }
    this.setState(prevState => ({
      ...prevState,
      loginData: {
        ...loginData,
      },
    }));
  };

  //signin page validation
  validate = () => {
    const {loginData} = this.state;
    let loginDataError = {
      ...this.state.loginDataError,
    };
    const {email, password} = loginData;
    loginDataError = {
      emailError: '',
      passwordError: '',
    };

    if (!email.length > 0) {
      loginDataError.emailError = 'Required';
    }

    if (email.length > 0 && !email_regex.test(email)) {
      loginDataError.emailError = 'Invalid Email';
    }

    if (!password.length > 0) {
      loginDataError.passwordError = 'Required';
    }

    if (password.length > 0 && !password_regex.test(password)) {
      loginDataError.passwordError = 'Invalid Password';
    }

    this.setState({
      loginDataError: {
        ...loginDataError,
      },
    });

    if (loginDataError.emailError || loginDataError.passwordError) {
      return false;
    }
    return true;
  };

  //sent login details to server for login
  submitForm = event => {
    event.preventDefault();
    const {email, password} = this.state.loginData;
    const isValid = this.validate();

    if (isValid) {
      // console.log('valid');
      this.props.userSignIn({
        loginDetails: {
          email: email,
          password: password,
        },
      });
    }
  };

  componentDidMount() {
    this.props.hideRedirectLinkLoader();
    const {signinStatus, history, authUser, userRole} = this.props;
    if (authUser !== null && signinStatus && userRole !== null) {
      history.push(`/app`);
    }
  }

  static getDerivedStateFromProps(nextProps) {
    const {signinStatus, history, authUser, userRole} = nextProps;
    const {serverConfig} = !!nextProps.clientConfigration && nextProps.clientConfigration;
    isEmpty(serverConfig) && history.push('/configration');
    // if already user logged in (browser back navigation). and user login to app
    if (authUser !== null && signinStatus && userRole !== null) {
      history.push(`/app`);
    }

    return null;
  }

  render() {
    const {loginData, loginDataError} = this.state;
    const {email, password} = loginData;
    const {emailError, passwordError} = loginDataError;
    const {loader, errorDisplay, errorMessage, pageName, classes, redirectLoader, clientConfigration} = this.props;
    const {serverConfig} = !!clientConfigration && clientConfigration;

    return (
      <div className="app-container">
        <div className="app-main-container">
          <div className="login-page forget-password-page">
            <div className="row full-height">
              <div className="col-lg-4 col-md-5 left-section">
                <p className="back-button  mbl-hide bk-btn-des">
                  <a href={serverConfig.institute_landingpage_url} variant="body2" className="back-home-link" style={{textDecoration: 'none'}}>
                    <img className="" alt="icon" src={process.env.PUBLIC_URL + '../../img/back-arrow.svg'} /> Back to Home
                  </a>
                </p>
                <p className="back-button des-hide">
                  <a href={serverConfig.institute_landingpage_url} variant="body2" className="back-home-link" style={{textDecoration: 'none'}}>
                    <img className="" alt="icon" src={process.env.PUBLIC_URL + '../../img/back-arrow.svg'} /> Back to Home
                  </a>
                </p>
                <div className="login-box-wrap forget-password">
                  <div className="logo-wrap">
                    <img className="" alt="icon" src={serverConfig.institute_logo} />
                  </div>
                  <div>
                    {errorDisplay && pageName === 'SignIn' && (
                      <div className="row form-error-wrap">
                        <span className="default-error">
                          <img src="../img/error-icon.svg" alt="error-Icon"></img>
                          {errorMessage}
                        </span>
                      </div>
                    )}
                    <h2>LOG IN</h2>
                    <h3>Join our growing team of certified installers</h3>
                    <form noValidate className={classes.formWrapper} autoComplete="off" onSubmit={this.submitForm}>
                      <Grid container>
                        <Grid xs={12} item>
                          <TextField
                            id="email"
                            label="Email"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            className={classes.error}
                            inputProps={{
                              style: {
                                // padding: 13,
                                backgroundColor: emailError ? '#FEF7F5' : '#FFFFFF',
                              },
                            }}
                            value={email}
                            onChange={this.handleChange}
                            onBlur={this.handleChangeOnBlur}
                            autoFocus
                            error={emailError ? true : false}
                            disabled={loader}
                            name="email"
                          />
                          {emailError && (
                            <p
                              className="Error"
                              style={{
                                color: '#E05757',
                                fontSize: '14px',
                                marginTop: '-21px',
                              }}
                            >
                              <ErrorOutlineIcon
                                style={{
                                  fontSize: '14px',
                                  marginTop: '-4px',
                                  marginRight: '10px',
                                }}
                              />
                              {emailError}
                            </p>
                          )}
                        </Grid>
                        <Grid xs={12} item>
                          <TextField
                            value={password}
                            inputProps={{
                              maxLength: 25,
                              style: {
                                backgroundColor: passwordError ? '#FEF7F5' : '#FFFFFF',
                              },
                            }}
                            type="password"
                            id="password"
                            label="Password"
                            variant="outlined"
                            className={classes.error}
                            margin="normal"
                            fullWidth
                            error={passwordError ? true : false}
                            onChange={this.handleChange}
                            disabled={loader}
                            name="password"
                          />
                          {passwordError && (
                            <p
                              className="Error"
                              style={{
                                color: '#e05757',
                                fontSize: '14px',
                                marginTop: '-21px',
                              }}
                            >
                              <ErrorOutlineIcon
                                style={{
                                  fontSize: '14px',
                                  marginTop: '-4px',
                                  marginRight: '10px',
                                }}
                              />
                              {passwordError}
                            </p>
                          )}
                        </Grid>
                        <Button
                          disabled={loader || redirectLoader}
                          type="submit"
                          variant="contained"
                          className="default-btn"
                          color="primary"
                          margin="normal"
                          fullWidth
                        >
                          Login {(loader || redirectLoader) && <SubmitLoader />}
                        </Button>
                      </Grid>
                    </form>
                    <Link href="/forgot-password" variant="body2" className="forget-link">
                      Forgot your password?
                    </Link>

                    <p className="signup-link">
                      Do you need an account? <a href="/signup">Sign up</a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-7 right-section">
                <div className="login-heading-wrap">
                  <h1>
                    Amerikooler <br />
                    CERTIFIED INSTALLATIONS
                  </h1>
                  <p>
                    Join our growing team of certified Amerikooler installation and service partners and become part of a growing network of skilled providers
                    with access to new business opportunities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      userSignIn,
      hideRedirectLinkLoader,
    },
    dispatch,
  );
};

const mapStateToProps = ({auth, error}) => {
  const {loader, authUser, signinStatus, userRole, clientConfigration, userId, userTypeId, name, token, redirectLoader} = auth;
  const {errorDisplay, errorMessage, pageName} = error;
  return {
    loader,
    authUser,
    signinStatus,
    userRole,
    clientConfigration,
    errorDisplay,
    errorMessage,
    pageName,
    userId,
    userTypeId,
    name,
    token,
    redirectLoader,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SignIn));
