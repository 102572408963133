import React, {Component} from 'react';
import './EmailVerification.scss';
import GreetingHeader from 'components/GreetingHeader/GreetingHeader';
import {connect} from 'react-redux';
import getQueryParams from '../../util/QueryString';
import {emailVerification} from 'redux/actions/Auth';
import CircularProgress from '@material-ui/core/CircularProgress';
import {bindActionCreators} from 'redux';
class EmailVerification extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      email: '',
    };
  }

  componentDidMount() {
    const {emailVerification, location} = this.props;

    const userId = getQueryParams(location.search, 'id');
    const hash = getQueryParams(location.search, 'hash');
    const expires = getQueryParams(location.search, 'expires');
    const signature = getQueryParams(location.search, 'signature');
    const componenRoute = sessionStorage.getItem('component_route');

    if (userId !== '' && hash !== '' && expires !== '' && signature !== '') {
      emailVerification({user_id: userId, hash: hash, expires: expires});
    } else {
      this.props.setInitUrl(componenRoute);
      this.props.history.push(componenRoute);
    }
  }
  render() {
    const {loader, emailVerifiedStatus, emailVerifiedStatusCode, history} = this.props;
    return (
      <React.Fragment>
        <div className="app-container pre-emailVerification-wrapper">
          <div className="app-main-container">
            <div className="header-logo-hideInMobile">
              <GreetingHeader />
            </div>
            <div className="panel deep-purple"></div>

            {loader || emailVerifiedStatus === null ? (
              <div className="loaderForConfigrationPage">
                <CircularProgress />
              </div>
            ) : (
              <main className="freeBird">
                <div className="container d-flex flex-column justify-content-center vh-100 ">
                  <div className="row justify-content-center">
                    <div className="col-md-7 ">
                      <div className="card card-z-index">
                        <div className="card-content">
                          <div className="parent-frame-icon">
                            {!emailVerifiedStatus || emailVerifiedStatusCode?.code === 409 ? (
                              <div className="frame-icon">
                                <img src="../img/Frame.svg" alt="success" />
                              </div>
                            ) : (
                              <div className="frame-error-icon">
                                <img src="../img/error-icon.svg" alt="error" />
                              </div>
                            )}
                          </div>
                          {!emailVerifiedStatus ? (
                            <div className="heading">
                              <p className="cong-p">CONGRATULATIONS</p>
                              <span>Your email verification is complete.</span>
                              <hr />
                              <div className="box-content alert-box-content">
                                <p>Click below to access your account.</p>
                              </div>
                            </div>
                          ) : (
                            <>
                              {emailVerifiedStatusCode?.code === 409 ? (
                                <div className="heading">
                                  <p className="cong-p">CONGRATULATIONS</p>
                                  <span> Your email verification has already been completed.</span>
                                  <hr />
                                  <div className="box-content alert-box-content">
                                    <p>Click below to access your account.</p>
                                  </div>
                                </div>
                              ) : emailVerifiedStatusCode?.code === 401 ? (
                                <div className="heading">
                                  <p className="cong-p cong-p-error">Opps!</p>
                                  <span>Your email verification link has expired.</span>
                                  <hr />
                                  <div className="box-content alert-box-content">
                                    <p>Click below to access your account.</p>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <div className="heading">
                                    <p className="cong-p cong-p-error">Opps!</p>
                                    <span>{emailVerifiedStatusCode?.message}</span>
                                    <hr />
                                    <div className="box-content alert-box-content">
                                      <p>Click below to access your account.</p>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                          <button
                            type="button"
                            className="default-btn"
                            disabled={loader}
                            onClick={() => {
                              history.push('/signin');
                            }}
                          >
                            Login
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{position: 'absolute', left: '0px', bottom: '0px'}} className="hideOnMobile">
                  <img className="sideHeaderImage" alt="icon" src={process.env.PUBLIC_URL + '../../../../img/side-footer.svg'} />
                </div>
              </main>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      emailVerification,
    },
    dispatch,
  );
};

const mapStateToProps = ({auth}) => {
  const {emailVerifiedStatus, emailVerifiedStatusCode, loader} = auth;

  return {
    loader,
    emailVerifiedStatus,
    emailVerifiedStatusCode,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerification);
