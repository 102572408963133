import * as CertifiedInstallerType from '../../constants/ActionTypes';
import {all, fork, put, takeLatest} from 'redux-saga/effects';
import {
  certifiedInstallerHideLoader,
  certifiedInstallerShowLoader,
  fetchCertifiedInstallerDataSuccess,
  dropdownStateListSuccess,
  sendMailFromFooterFlag,
} from '../actions/CertifiedInstaller';
import {errorHandler} from '../actions/Error';
import {mainAxios} from 'util/Api';
import axios from 'axios';
import { sortOn } from 'util/Extra';

// CertifiedInstaller details fetch worker
export function* fetchDataCertifiedInstaller() {
  try {
    yield put(certifiedInstallerShowLoader());
    const certifiedInstallerResponse = yield mainAxios.get(`/certified-installers`);
    if (certifiedInstallerResponse.data) {
      yield put(fetchCertifiedInstallerDataSuccess(certifiedInstallerResponse.data));
    }
  } catch (error) {
    if (!error.response) {
      yield put(errorHandler({error: error, page: ''}));
    }
  }
  yield put(certifiedInstallerHideLoader());
}

//  Certified Installer fetch watcher
export function* fetchCertifiedInstallerDataWatcher() {
  yield takeLatest(CertifiedInstallerType.FETCH_CERTIFIED_INSTALLER_DATA, fetchDataCertifiedInstaller);
}

// CertifiedInstaller dropdown states list fetch worker
export function* fetchDropdownStateListCertifiedInstaller({payload}) {
  try {
    //yield put(certifiedInstallerShowLoader());
    const country = payload;
    const certifiedInstallerResponse = yield mainAxios.get(`/get-states-by-country/${country}`);

    if (certifiedInstallerResponse.data) {
      const stateList = certifiedInstallerResponse.data;
      const certifiedInstallerState = stateList.length > 0 ? stateList.sort(sortOn('state_name')) : [];
      yield put(dropdownStateListSuccess(certifiedInstallerState));
    }
  } catch (error) {
    if (!error.response) {
      yield put(errorHandler({error: error, page: ''}));
    }
  }
  //yield put(certifiedInstallerHideLoader());
}

// CertifiedInstaller dropdown states list fetch watcher

export function* fetchDropdownStateListWatcher() {
  yield takeLatest(CertifiedInstallerType.FETCH_DROPDOWN_STATE_LIST, fetchDropdownStateListCertifiedInstaller);
}
 //send email from footer 

export function* sendFooterEmail({payload}) {
  const formData = new FormData();
    for (var key in payload) {
      formData.append(key, payload[key]);
    }
  try {
    const sendEmailFromFooter = yield axios.post(`http://www.amerikooler.com/`, formData);

    // console.log(sendEmailFromFooter)
    if (sendEmailFromFooter.data) {
      yield put(sendMailFromFooterFlag(true));
    }
  } catch (error) {
    if (!error.response) {
      yield put(errorHandler({error: error, page: ''}));
    }
    yield put(sendMailFromFooterFlag(false));

  }
  // yield put(sendMailFromFooterFlag(false));
}

// CertifiedInstaller send email footer details watcher
export function* sendFooterEmailWatcher() {
  yield takeLatest(CertifiedInstallerType.SEND_FOOTER_MAIL, sendFooterEmail);
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([fork(fetchCertifiedInstallerDataWatcher), fork(fetchDropdownStateListWatcher), fork(sendFooterEmailWatcher)]);
}
