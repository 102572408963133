// Header component for common header

import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {isEmpty} from 'lodash';
import './SimpleHeader.scss';
import HeaderMenu from '../../containers/HeaderMenu/HeaderMenu';
import UserInfo from 'components/UserInfo';
import {connect} from 'react-redux';

// const userRole = 'Guest';

const SimpleHeader = props => {
  const {clientConfigration} = props;
  const {serverConfig} = !!clientConfigration && clientConfigration;
  return (
    <div>
      <CssBaseline />
      <header className="header-wrap">
        <div className="container-full">
          <div className="row">
            <div className="col-md-6 col-7">
              <div className="logo-wrap">
                {!!props.val && props.val === 'ext' ? (
                  <img className="logo-img" alt="icon" src={serverConfig.institute_logo} />
                ) : (
                  <img className="logo-img" alt="icon" src={serverConfig.institute_logo} />
                )}
              </div>
            </div>
            <div className="col-md-6 col-5">
              <div className="profile-wrap">
                {isEmpty(props.val) && <HeaderMenu val={`${!!props.val && props.val ? 'ext' : ''}`} />}

                <div className="hideOnMobile">
                  <UserInfo />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

const mapStateToProps = ({auth}) => {
  const {clientConfigration} = auth;

  return {
    clientConfigration,
  };
};
export default connect(mapStateToProps, null)(SimpleHeader);
