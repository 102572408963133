//Menu component for header realted option menu

import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
// import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import './HeaderMenu.scss';
import {connect} from 'react-redux';
import {userSignOut} from '../../redux/actions/Auth';
import {Link} from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import {bindActionCreators} from 'redux';
import {firstLetter} from 'util/Extra';

class HeaderMenu extends Component {
  state = {
    anchorEl: null,
  };

  //open side menu popup
  handleClick = event => {
    this.setState({anchorEl: event.currentTarget});
  };

  //closed side menu popup
  handleClose = () => {
    this.setState({anchorEl: null});
  };

  // return to course library and clear reducer of lessonLibrary, launchSimulator and simulator
  returnToCourseLibrary = () => {
    // this.props.clearSimulatorData();
    // this.props.clearLaunchSimulatorData();
    // this.props.clearLessonLibraryData();
    // this.props.emptyHmiSimulatorData();
    // this.props.emptyFileData();
    // this.props.emptyTestsData();
    // this.props.emptyVrSimulatorData();
    this.handleClose();
  };
  menuClickHandler = () => {
    this.handleClose();
  };

  render() {
    const {authUser, signinStatus, profileData, clientConfigration} = this.props;
    const {serverConfig} = !!clientConfigration && clientConfigration;
    const {anchorEl} = this.state;

    return (
      <div className="MenuBar hideOnTop">
        {window.location.pathname !== '/signupgreeting' && window.location.pathname.includes('api/email/verify') === false && (
          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick}>
            <img className="MenuIcon" alt="icon" src={process.env.PUBLIC_URL + '../../img/menu-icon.svg'} />
          </Button>
        )}
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={this.handleClose}>
          <MenuItem disabled={true}>
            <PersonOutlineIcon className="MenuIcons" fontSize="small" />
            {`Hi, ${authUser === null ? 'Guest' : firstLetter(profileData.first_name)}`}
          </MenuItem>
          <MenuItem onClick={this.handleClose} className="Link">
            <CloseIcon className="MenuIcons Link" fontSize="small" />
            Close
          </MenuItem>
          {!authUser && !signinStatus && (
            <a href={serverConfig.institute_landingpage_url} className="Link">
              <MenuItem
                onClick={() => {
                  this.menuClickHandler();
                }}
              >
                <ArrowBackIcon className="MenuIcons" fontSize="small" />
                Home
              </MenuItem>
            </a>
          )}
          {/* <Link to="/ecommerce/product" className="Link">
            <MenuItem
              onClick={() => {
                this.menuClickHandler();
              }}
            >
              <ArrowBackIcon className="MenuIcons Link" fontSize="small" />
              Product
            </MenuItem>
          </Link> */}
          {/* <Link to="/ecommerce/Cart" className="Link">
            <MenuItem
              onClick={() => {
                this.menuClickHandler();
              }}
            >
              <ArrowBackIcon className="MenuIcons Link" fontSize="small" />
              Cart
            </MenuItem>
          </Link> */}
          {authUser && signinStatus ? (
            <Link to="/" className="Link">
              <MenuItem
                onClick={() => {
                  sessionStorage.removeItem('component_route');
                  this.props.userSignOut();
                }}
              >
                <ExitToAppOutlinedIcon className="MenuIcons" fontSize="small" />
                Logout
              </MenuItem>
            </Link>
          ) : (
            <div>
              {/* <Link to="/certifiedinstaller" className="Link">
                <MenuItem
                  onClick={() => {
                    this.menuClickHandler();
                  }}
                >
                  <ArrowBackIcon className="MenuIcons Link" fontSize="small" />
                  Certified Installer
                </MenuItem>
              </Link> */}
              <Link to="/signin" className="Link">
                <MenuItem
                  onClick={() => {
                    this.menuClickHandler();
                  }}
                >
                  <ArrowBackIcon className="MenuIcons" fontSize="small" />
                  Login
                </MenuItem>
              </Link>
            </div>
          )}
        </Menu>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      userSignOut,
    },
    dispatch,
  );
};

const mapStateToProps = ({auth, lessonLibrary, router, profile}) => {
  const {employeeId, authUser, signinStatus, clientConfigration} = auth;
  const {profileData} = profile;

  const {location} = router;
  return {
    employeeId,
    location,
    authUser,
    signinStatus,
    profileData,
    clientConfigration,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenu);
