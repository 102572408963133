import * as types from '../../constants/ActionTypes';
const INIT_STATE = {
  loader: false,
  resendEmailLoader: false,
  initURL: '',
  redirectLoader: false,
  alertMessage: '',
  showMessage: false,
  authUser: null,
  userRole: null,
  userId: '',
  signinStatus: false,
  session_id: null,
  updatingPassword: false,
  passwordToken: null,
  updatePasswordCompleted: false,
  updateDefaultPassword: false,
  userIsAdmin: false,
  purchasedCourse: null,
  dropDownCityList: [],
  cityStateName: null,
  emailVerifyData: null,
  emailVerifiedStatus: null,
  emailVerifiedStatusCode:null,
  emailVerificationUserErrorData: null,
  createLeadResponseData: null,
  passwordTokenDetails: null,
  /* config: {
    defaultPconfig: {
      title_name: config.sanofi.title_name,
      sub_title_name: config.sanofi.sub_title_name,
      subdomain: config.sanofi.subdomain,
      logo_url: config.sanofi.logo_url,
      footer_text: config.sanofi.footer_text,
      api_key: config.sanofi.api_key,
      contact: {email: config.sanofi.email},
      meta_details: {
        title: config.sanofi.meta_title,
        favicon: config.sanofi.meta_favicon,
        description: config.sanofi.meta_description,
      },
    },
    appConfig: null,
  }, */
  clientConfigration: {
    serverConfig: null,
  },
  employeeId: null,
  name: '',
  supervisorId: null,
  configLoader: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        token: null,
        authUser: null,
        userId: null,
        initURL: '',
        loader: false,
        signinStatus: false,
        supervisorId: null,
      };
    }

    case types.SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        signinStatus: true,
        loader: false,
      };
    }

    case types.SET_SUPERVISOR_ID: {
      return {
        ...state,
        ...action.payload,
        supervisorId: action.payload.userId,
      };
    }

    case types.SET_EMPLOYEE_ID: {
      return {
        ...state,
        ...action.payload,
        employeeId: action.payload.employeeId,
        name: action.payload.name,
      };
    }

    case types.USER_DATA: {
      return {
        ...state,
        authUser: action.payload,
      };
    }

    case types.ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }

    case types.ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }

    case types.SHOW_RESEND_LINK_LOADER: {
      return {
        ...state,
        resendEmailLoader: true,
      };
    }

    case types.HIDE_RESEND_LINK_LOADER: {
      return {
        ...state,
        resendEmailLoader: false,
      };
    }

    case types.SHOW_REDIRECT_LINK_LOADER: {
      return {
        ...state,
        redirectLoader: true,
      };
    }

    case types.HIDE_REDIRECT_LINK_LOADER: {
      return {
        ...state,
        redirectLoader: false,
      };
    }

    case types.SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }
    case types.SET_CONFIG_LOADER: {
      return {
        ...state,
        configLoader: action.payload,
      };
    }

    case types.SET_PCONFIG: {
      return {
        ...state,
        clientConfigration: {
          ...state.clientConfigration,
          serverConfig: action.payload,
        },
      };
    }
    case types.HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false,
      };
    }

    case types.UPDATE_PASSWORD_LOADER: {
      return {
        ...state,
        updatingPassword: action.payload,
      };
    }

    case types.SET_UPDATE_PASSWORD_TOKEN: {
      return {
        ...state,
        passwordTokenDetails: action.payload,
      };
    }

    case types.UPDATE_PASSWORD_COMPLETED: {
      return {
        ...state,
        updatePasswordCompleted: action.payload,
      };
    }

    case types.HAS_COURSE_RESPONSE: {
      return {
        ...state,
        purchasedCourse: action.payload,
      };
    }

    case types.SET_USER_IS_ADMIN: {
      return {
        ...state,
        userIsAdmin: action.payload,
      };
    }

    case types.DROPDOWN_CITY_LIST_SUCCESS: {
      return {
        ...state,
        dropDownCityList: action.payload,
      };
    }

    case types.CITY_STATE_DATA_BY_ZIP_SUCCESS: {
      return {
        ...state,
        cityStateName: action.payload,
      };
    }

    case types.SEND_EMAIL_VERIFICATION: {
      return {
        ...state,
        emailVerifyData: action.payload,
      };
    }
    case types.VERIFY_USER_EMAIL_EXIST: {
      return {
        ...state,
        emailVerifiedStatus: action.payload,
      };
    }
    case types.EMAIL_VERIFICATION_STATUE_CODE: {
      return {
        ...state,
        emailVerifiedStatusCode: action.payload,
      };
    }
    case types.SAVE_CREATE_LEAD_RESPONSE_DATA: {
      return {
        ...state,
        createLeadResponseData: action.payload,
      };
    }
    case types.SET_EMAIL_VERIFICATION_USER_ERROR_DATA: {
      return {
        ...state,
        emailVerificationUserErrorData: action.payload,
      };
    }
    default:
      return state;
  }
};
