/* istanbul ignore file */

//Forgot Password Component
import React, {Component, createRef} from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {email_regex, email_regex_numbers} from '../util/Extra';
import {connect} from 'react-redux';
import {forgotPasswordStart} from '../redux/actions/Auth';
import {bindActionCreators} from 'redux';
import {withStyles} from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SubmitLoader from 'components/SubmitLoader/SubmitLoader';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../config/config';

const styles = theme => ({
  root: {
    '& .MuiInputLabel-formControl': {
      top: '6% !important',
    },
  },
  paper: {
    marginTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: ' 50px',
  },
  input: {
    background: '#f4f7fc',
    '&:-webkit-autofill': {
      background: '#f4f7fc',
    },
  },
  btnsubmit: {
    width: '100%',
    fontFamily: 'ITC Avant Garde Std Md',
    padding: '10px 25px !important',
    borderRadius: ' 50px',
    fontSize: '16px',
    marginBottom: '15px',
    background: '#525CA3',
    '&:hover ': {
      background: '#525CA3',
    },
  },
  error: {
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E05757',
    },
  },
});

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.myRef = createRef();
    this.state = {
      personalDetails: {
        email: '',
      },
      emailDataError: {
        emailError: '',
      },
    };
  }

  //Validation for email
  validate = () => {
    const {email} = this.state.personalDetails;
    let emailDataError = this.state.emailError;
    emailDataError = {
      emailError: '',
    };

    if (!email.length > 0) {
      emailDataError.emailError = 'Required';
    }

    if (email.length > 0 && !email_regex.test(email)) {
      emailDataError.emailError = 'Invalid Email';
    }

    if (email.length > 0 && !email_regex_numbers.test(email)) {
      emailDataError.emailError = 'Invalid Email';
    }

    this.setState({
      emailDataError: {
        ...emailDataError,
      },
    });

    if (emailDataError.emailError) {
      return false;
    }
    return true;
  };

  //Onsubmit email sent to server for password reset
  submitForm = async event => {
    event.preventDefault();
    const {email} = this.state.personalDetails;
    const isValid = this.validate();
    const recatpchaToken = await this.myRef.current.executeAsync();
    this.myRef.current.reset();
    if (isValid) {
      this.props.forgotPasswordStart({
        email: email,
        recaptchaToken: recatpchaToken,
      });
    }
  };

  //set email in state
  handleChange = e => {
    const {personalDetails} = {...this.state};
    const {emailDataError} = {...this.state};

    personalDetails[e.target.name] = e.target.value;

    //in case if error is their and user entered a character then remove the message
    if (emailDataError[e.target.name + 'Error'] !== '') {
      emailDataError[e.target.name + 'Error'] = '';
    }

    this.setState(prevState => ({
      ...prevState,
      personalDetails: {...personalDetails},
      emailDataError: {...emailDataError},
    }));
  };

  // for remove unwanted space on email typing
  handleChangeOnBlur = e => {
    const {personalDetails} = {...this.state};
    if (e.target.name === 'email') {
      personalDetails[e.target.name] = e.target.value.trim();
    }
    this.setState(prevState => ({
      ...prevState,
      personalDetails: {...personalDetails},
    }));
  };

  componentDidMount() {
    const {signinStatus, history, authUser, userRole} = this.props;
    if (authUser !== null && signinStatus && userRole !== null) {
      history.push(`/app`);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {signinStatus, history, authUser, userRole} = nextProps;
    if (authUser !== null && signinStatus && userRole !== null) {
      history.push(`/app`);
    }

    return null;
  }

  render() {
    const {clientConfigration, classes, loader, errorMessage, errorDisplay, pageName} = this.props;
    const {serverConfig} = !!clientConfigration && clientConfigration;

    const {email} = this.state.personalDetails;
    const {emailError} = this.state.emailDataError;
    return (
      <div className={`app-container`}>
        <div className="app-main-container">
          <div className="login-page forget-password-page">
            <div className="row full-height">
              <div className="col-lg-4 col-md-5 left-section">
                <div className="login-box-wrap forget-password">
                  <div className="logo-wrap">
                    <img className="" alt="icon" src={serverConfig.institute_logo} />
                  </div>
                  {errorDisplay && pageName === 'ForgetPassword' && (
                    <div className="row form-error-wrap">
                      <span className="default-error">
                        <img src="../img/error-icon.svg" alt="loading..."></img>
                        {errorMessage}
                      </span>
                    </div>
                  )}
                  <h2>RESET YOUR PASSWORD</h2>
                  <h3>Please enter your username or email address. You will receive an email message with instructions on how to reset your password.</h3>
                  <form className={classes.root} noValidate autoComplete="off" onSubmit={this.submitForm}>
                    <Grid container>
                      <Grid xs={12} item>
                        <TextField
                          id="email"
                          name="email"
                          label="Email"
                          variant="outlined"
                          margin="normal"
                          className={classes.error}
                          fullWidth
                          autoFocus
                          error={emailError ? true : false}
                          disabled={loader}
                          value={email}
                          onChange={this.handleChange}
                          onBlur={this.handleChangeOnBlur}
                          inputProps={{
                            style: {
                              padding: 13,
                              backgroundColor: emailError ? '#FEF7F5' : '#FFFFFF',
                            },
                          }}
                        />
                        {emailError && (
                          <p
                            className="Error"
                            style={{
                              color: '#E05757',
                              fontSize: '14px',
                              marginTop: '-21px',
                            }}
                          >
                            <ErrorOutlineIcon
                              style={{
                                fontSize: '14px',
                                marginTop: '-4px',
                                marginRight: '10px',
                              }}
                            />
                            {emailError}
                          </p>
                        )}
                      </Grid>
                      <Button type="submit" variant="contained" className="default-btn" color="primary" margin="normal" fullWidth disabled={loader}>
                        SEND RESET PASSWORD LINK {loader && <SubmitLoader />}
                      </Button>
                    </Grid>
                  </form>
                  {/* <a href="#" className="forget-link">
              Forgot your password?
            </a> */}
                  <Link href="/signin" variant="body2" className="back-link" style={{textDecoration: 'none'}}>
                    <img className="" alt="icon" src={process.env.PUBLIC_URL + '../../img/back-arrow.svg'} /> Back
                  </Link>
                </div>
              </div>
              <div className="col-lg-8 col-md-7 right-section">
                {/* <img className="login-poster-image" alt="icon" src={process.env.PUBLIC_URL + '../../img/login-poster-image.png'} /> */}
                <div className="login-heading-wrap">
                  <h1>
                    Amerikooler <br />
                    CERTIFIED INSTALLATIONS
                  </h1>
                  <p>
                    Join our growing team of certified Amerikooler installation and service partners and become part of a growing network of skilled providers
                    with access to new business opportunities.
                  </p>
                </div>
              </div>
              <ReCAPTCHA sitekey={config.recaptchaSiteKey} size="invisible" ref={this.myRef} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      forgotPasswordStart,
    },
    dispatch,
  );
};

const mapStateToProps = ({auth, error}) => {
  const {loader, signinStatus, authUser, config, clientConfigration} = auth;
  const {errorDisplay, errorMessage, pageName} = error;

  return {
    loader,
    signinStatus,
    authUser,
    config,
    errorMessage,
    errorDisplay,
    pageName,
    clientConfigration,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ForgotPassword));
