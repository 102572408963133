import React, {useState, useEffect, useRef} from 'react';
import './Registration.scss';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {email_regex, getFirstAndLastName} from '../../util/Extra';
import {bindActionCreators} from 'redux';
import {userSignUp} from '../../redux/actions/Auth';
import {fetchDropdownStateList} from '../../redux/actions/CertifiedInstaller';
import {fetchDropdownCityList, fetchCityStateByZip, dropdownCityListSuccess, sendPrefilledSignupValue} from '../../redux/actions/Auth';
import {connect} from 'react-redux';
import SubmitLoader from 'components/SubmitLoader/SubmitLoader';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../../config/config';
import CustomMaskedInput from 'components/CustomMaskedInput/CustomMaskedInput';
import {globalMessages, formatPhoneNumber, isNumber, firstLetter} from 'util/Extra';
import Autocomplete from '@material-ui/lab/Autocomplete';
import getQueryParams from 'util/QueryString';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {Stack} from '@mui/material';

const useStyles = makeStyles(theme => ({
  error: {
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E05757',
    },
  },
  requiredText: {
    color: '#e05757',
  },
  root: {
    '& .MuiFormLabel-root': {
      lineHeight: 1.5,
    },
  },
  inputRoot: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 4,
    },
  },
}));
const regex = {
  first_name: /^[a-zA-Z]{3,50}$/,
  last_name: /^[a-zA-Z'/ /']{3,100}$/,
  email: email_regex,
  zip: /^[0-9]{5}$/,
  phone: /^[0-9 -]{12}$/,
  company: /^[a-zA-Z'/ /']{1,255}$/,
  address: /^.{1,255}$/,
  address2: /^.{1,70}$/,
  city: /^[a-zA-Z'/ /']{1,50}$/,
  password: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,25}$/,
  c_password: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,25}$/,
};

const helperText = {
  password: '',
  first_name: '',
  last_name: '',
  email: '',
  company: '',
  country: '',
  address: '',
  address2: '',
  zip: '',
  state: '',
  city: '',
  phone: '',
  c_password: '',
};

const displayName = {
  first_name: 'First Name',
  last_name: 'Last Name',
  email: 'Email',
  company: 'Company',
  // country: 'Country',
  address: 'Address',
  address2: 'Address2',
  zip: 'Zip Code',
  state: 'State',
  city: 'City',
  phone: 'Phone Number',
  password: 'Password',
  c_password: 'Confirm Password',
};

const fieldType = {
  first_name: 'text',
  last_name: 'text',
  email: 'text',
  company: 'text',
  // country: 'select',
  address: 'text',
  address2: 'text',
  zip: 'text',
  state: 'select',
  city: 'select',
  phone: 'text',
  password: 'password',
  c_password: 'password',
};

function Registration(props) {
  const classes = useStyles();
  const {errorDisplay, errorMessage, loader, pageName, clientConfigration} = props;
  const {serverConfig} = !!clientConfigration && clientConfigration;

  const reRef = useRef();

  const [registerData, setRegisterData] = useState({
    data: {
      first_name: '',
      last_name: '',
      email: '',
      company: '',
      // country: '',
      address: '',
      address2: '',
      zip: '',
      state: '',
      city: '',
      phone: '',
      password: '',
      c_password: '',
    },
    error: {
      first_name: '',
      last_name: '',
      email: '',
      company: '',
      // country: '',
      address: '',
      address2: '',
      zip: '',
      state: '',
      city: '',
      phone: '',
      password: '',
      c_password: '',
    },
  });
  const [isCityStateAvailable, setIsCityStateAvailable] = useState(false);

  //validate the fields
  const isValid = () => {
    let flag = true;
    const oldObj = registerData.error;
    const password = registerData.data['password'];

    for (const key in registerData.error) {
      if (registerData.data[key].trim() === '' && key !== 'company' && key !== 'address2') {
        oldObj[key] = 'Required';
        flag = false;
      } else {
        if (
          registerData.data[key].trim() !== '' &&
          regex.hasOwnProperty(key) &&
          !regex[key].test(registerData.data[key].trim()) &&
          key !== 'password' &&
          key !== 'first_name' &&
          key !== 'last_name' &&
          key !== 'company' &&
          key !== 'address' &&
          key !== 'address2' &&
          key !== 'city' &&
          key !== 'zip' &&
          key !== 'phone'
        ) {
          oldObj[key] = 'Invalid ' + displayName[key];
          flag = false;
        } else if (regex.hasOwnProperty(key) && !regex[key].test(registerData.data[key].trim()) && key === 'password') {
          oldObj[key] = globalMessages.passwordValidationErrorMessage;
          flag = false;
        } else if (regex.hasOwnProperty(key) && !regex[key].test(registerData.data[key].trim()) && key === 'first_name') {
          oldObj[key] = globalMessages.firstNameValidationErrorMessage;
          flag = false;
        } else if (regex.hasOwnProperty(key) && !regex[key].test(registerData.data[key].trim()) && key === 'last_name') {
          oldObj[key] = globalMessages.lastNameValidationErrorMessage;
          flag = false;
        } else if (registerData.data[key].trim() !== '' && regex.hasOwnProperty(key) && !regex[key].test(registerData.data[key].trim()) && key === 'company') {
          oldObj[key] = globalMessages.companyNameValidationErrorMessage;
          flag = false;
        } else if (regex.hasOwnProperty(key) && !regex[key].test(registerData.data[key].trim()) && key === 'address') {
          oldObj[key] = globalMessages.addressValidationErrorMessage;
          flag = false;
        } else if (registerData.data[key].trim() !== '' && regex.hasOwnProperty(key) && !regex[key].test(registerData.data[key].trim()) && key === 'address2') {
          oldObj[key] = globalMessages.address2ValidationErrorMessage;
          flag = false;
        } else if (regex.hasOwnProperty(key) && !regex[key].test(registerData.data[key].trim()) && key === 'city') {
          oldObj[key] = globalMessages.cityNameValidationErrorMessage;
          flag = false;
        } else if (regex.hasOwnProperty(key) && !regex[key].test(registerData.data[key].trim()) && key === 'zip') {
          oldObj[key] = globalMessages.zipCodeValidationErrorMessage;
          flag = false;
        } else if (regex.hasOwnProperty(key) && !regex[key].test(registerData.data[key].trim()) && key === 'phone') {
          oldObj[key] = globalMessages.phoneNumberValidationErrorMessage;
          flag = false;
        } else {
          if (key === 'c_password' && registerData.data[key].localeCompare(password) !== 0) {
            oldObj[key] = 'Confirmed password should be the same as the password.';
            flag = false;
          } else {
            oldObj[key] = '';
          }
        }
      }
    }

    setRegisterData(prevState => ({
      ...prevState,
      error: {
        ...oldObj,
      },
    }));
    return flag;
  };
  //once form is submitted check all the fields with required and corresponding regex
  const handleSubmit = async event => {
    event.preventDefault();
    if (!isValid()) return;
    const recatpchaToken = await reRef.current.executeAsync();
    reRef.current.reset();
    const {createLeadResponseData} = props;
    props.userSignUp({registerData: registerData.data, recaptchaToken: recatpchaToken, createLeadResponseData: createLeadResponseData});
  };

  //change the value of each input in state object
  const handleOnChange = event => {
    const registerDataTemp = registerData;
    registerDataTemp.data[event.target.name] = event.target.value;

    if (registerDataTemp.error[event.target.name] !== '') {
      registerDataTemp.error[event.target.name] = '';
    }
    // if (event.target.name === 'country') {
    //   props.fetchDropdownStateList(event.target.value);
    // }
    if (event.target.name === 'zip') {
      event.target.value.length === 5 && isNumber(event.target.value) && props.fetchCityStateByZip(event.target.value);
      setIsCityStateAvailable(true);
    }

    setRegisterData(prevState => ({
      ...registerDataTemp,
    }));
  };

  // autocomplete search state
  const onTagsStateChange = (event, values) => {
    const registerDataTemp = registerData;
    if (!!values) {
      registerDataTemp.data['state'] = values.state_name;
      registerData.data.city = '';
      props.fetchDropdownCityList(values.state_code);
    } else {
      registerDataTemp.data['state'] = '';
      props.dropdownCityListSuccess([]);
    }
    if (registerDataTemp.error['state'] !== '') {
      registerDataTemp.error['state'] = '';
    }

    setRegisterData(prevState => ({
      ...registerDataTemp,
    }));
  };
  // autocomplete search city
  const onTagsCityChange = (event, values) => {
    const registerDataTemp = registerData;
    if (!!values) {
      registerDataTemp.data['city'] = values.city_name;
    } else {
      registerDataTemp.data['city'] = '';
    }
    if (registerDataTemp.error['city'] !== '') {
      registerDataTemp.error['city'] = '';
    }

    setRegisterData(prevState => ({
      ...registerDataTemp,
    }));
  };
  //for updating the state on blur
  const handleOnBlur = event => {
    const registerDataTemp = registerData;
    // const key = event.target.name;
    registerDataTemp.data[event.target.name] = event.target.value;
    setRegisterData(prevState => ({
      ...prevState,
      data: {
        ...registerDataTemp.data,
      },
    }));
  };

  useEffect(() => {
    props.fetchDropdownStateList('US');
    const {signinStatus, history, authUser, userRole, sendPrefilledSignupValue, location} = props;

    // thankyou page url data prefilled, in signup by session storage
    const firstUserNameThankyouFormSessionStorage = sessionStorage.getItem('name1');
    const lastUserNameThankyouFormSessionStorage = sessionStorage.getItem('name2');
    const emailIdThankyouFormSessionStorage = sessionStorage.getItem('email_id');
    const companyNameThankyouFormSessionStorage = sessionStorage.getItem('company');
    const phoneNumberThankyouFormSessionStorage = sessionStorage.getItem('phone_number');

    // landing page url data profilled in Signup
    const userName = getQueryParams(location.search, 'name');
    const email = getQueryParams(location.search, 'email');
    const company = getQueryParams(location.search, 'company');
    const phoneNumber = getQueryParams(location.search, 'phone');
    const fromSource = getQueryParams(location.search, 'source');

    const registerDataTemp = registerData;
    const FLName = getFirstAndLastName(userName);
    const firstUserName = FLName[0];
    const lastUserName = FLName[1];
    const userMail = !!email ? decodeURIComponent(email) : '';
    const userCompany = !!company ? company.replace(/\+/g, ' ') : '';
    const userPhoneNumber = !!phoneNumber ? formatPhoneNumber(phoneNumber.replace(/\+/g, ' ')) : '';

    registerDataTemp.data['first_name'] = !!firstUserNameThankyouFormSessionStorage ? firstUserNameThankyouFormSessionStorage : firstUserName;
    registerDataTemp.data['last_name'] = !!lastUserNameThankyouFormSessionStorage ? lastUserNameThankyouFormSessionStorage : lastUserName;
    registerDataTemp.data['email'] = !!emailIdThankyouFormSessionStorage ? emailIdThankyouFormSessionStorage : userMail;
    registerDataTemp.data['company'] = !!companyNameThankyouFormSessionStorage ? companyNameThankyouFormSessionStorage : userCompany;
    registerDataTemp.data['phone'] = !!phoneNumberThankyouFormSessionStorage ? phoneNumberThankyouFormSessionStorage : userPhoneNumber;
    if (firstUserName !== '' && userMail !== '' && userPhoneNumber !== '' && fromSource !== '') {
      sendPrefilledSignupValue({
        first_name: firstUserName,
        last_name: lastUserName,
        email: userMail,
        company: userCompany,
        phone: userPhoneNumber,
        from_source: fromSource,
        callingPage: 'signup',
      });
      window.addEventListener(
        'popstate',
        function() {
          history.go(-2);
        },
        false,
      );
    }

    setRegisterData(prevState => ({
      ...registerDataTemp,
    }));
    if (authUser !== null && signinStatus && userRole !== null) {
      history.push(`/app`);
    }
  }, []);

  useEffect(() => {
    const {signinStatus, history, authUser, userRole} = props;
    if (authUser !== null && signinStatus && userRole !== null) {
      history.push(`/app`);
    }
  }, [props]);

  // city state Autofill according zipcode
  useEffect(() => {
    const {cityStateName, certifiedInstallerState} = props;
    const registerDataTemp = registerData;
    if (!!cityStateName && isCityStateAvailable) {
      const stateInfo = !!certifiedInstallerState && certifiedInstallerState.find(state => state.state_code === cityStateName.state_code);
      if (cityStateName.zip_code !== null || cityStateName.state_code !== null || cityStateName.city_name !== null) {
        props.fetchDropdownCityList(cityStateName.state_code);
        registerDataTemp.data['zip'] = cityStateName.zip_code;
        registerDataTemp.data['state'] = stateInfo.state_name;
        registerDataTemp.data['city'] = firstLetter(cityStateName.city_name);
      }
    } else {
      registerDataTemp.data['state'] = '';
      registerDataTemp.data['city'] = '';
    }
    setRegisterData(prevState => ({
      ...registerDataTemp,
    }));
  }, [props.cityStateName]);

  return (
    <div className="container-fluid signup-page">
      <div className="row">
        <div className="col-lg-12 text-center logoMain col-md-12">
        <a href={serverConfig.institute_landingpage_url}>
          <img className="" alt="icon" src={serverConfig.institute_logo} />
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="container registration-box">
            {errorDisplay && pageName === 'SignUp' && (
              <div className="row form-error-wrap">
                <span className="col-md-6 col-lg-4 default-error">
                  <img src="../img/error-yicon.svg"></img>
                  {errorMessage}
                </span>
              </div>
            )}
            <br />
            <h2>User Registration</h2>
            {props?.createLeadResponseData && (
              <div className="create-lead-status-message">
                {props?.createLeadResponseData.existing_lead ? (
                  <p>
                    <Stack direction={'row'} spacing={1}>
                      <CheckCircleIcon className="Check-Circle-Icon" />
                      <span> We already have your submission, Please fill all the details to complete your registration process.</span>
                    </Stack>
                  </p>
                ) : (
                  <p>
                    <Stack direction={'row'} spacing={1}>
                      <CheckCircleIcon className="Check-Circle-Icon" />
                      <span>Please fill all the details to complete your registration process.</span>
                    </Stack>
                  </p>
                )}
              </div>
            )}

            <h3>Join our growing team of certified installers</h3>
            <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                {Object.keys(registerData.data).map((key, idx) => {
                  if (fieldType[key] === 'text' || fieldType[key] === 'password')
                    return (
                      <Grid sm={6} xs={12} item key={key}>
                        <TextField
                          error={registerData.error[key] === '' ? false : true}
                          className={classes.error}
                          helperText={registerData.error[key] === '' ? helperText[key] : ''}
                          name={key}
                          label={key === 'company' || key === 'address2' ? `${displayName[key]}` : `${displayName[key]} *`}
                          type={fieldType[key]}
                          value={registerData.data[key]}
                          variant="outlined"
                          margin="normal"
                          onChange={handleOnChange}
                          onBlur={handleOnBlur}
                          fullWidth
                          InputProps={{
                            inputProps: {
                              style: {
                                padding: 13,
                                backgroundColor: registerData.error[key] === '' ? '#FFFFFF' : '#FEF7F5',
                              },
                              ...(key === 'first_name' && {maxLength: 50}),
                              ...(key === 'last_name' && {maxLength: 100}),
                              ...(key === 'email' && {maxLength: 255}),
                              ...(key === 'zip' && {maxLength: 5}),
                              ...(fieldType[key] === 'password' && {maxLength: 25}),
                            },
                            ...(key === 'phone' && {inputComponent: CustomMaskedInput}),
                          }}
                        />
                        {registerData.error[key] !== '' && registerData.error[key] !== 'Required' && (
                          <p
                            className="Error"
                            style={{
                              color: '#E05757',
                              fontSize: '14px',
                            }}
                          >
                            <ErrorOutlineIcon
                              style={{
                                fontSize: '14px',
                                marginTop: '-4px',
                                marginRight: '10px',
                              }}
                            />
                            {registerData.error[key]}
                          </p>
                        )}
                      </Grid>
                    );
                  else if (fieldType[key] === 'select' && key === 'state')
                    return (
                      <Grid sm={6} xs={12} item key={key}>
                        <Autocomplete
                          options={!!props.certifiedInstallerState ? props.certifiedInstallerState : []}
                          getOptionLabel={option => (typeof option !== 'string' ? option.state_name : option)}
                          value={registerData.data[key] ? registerData.data[key] : null}
                          className={classes.inputRoot}
                          onChange={onTagsStateChange}
                          renderInput={params => (
                            <TextField
                              {...params}
                              error={registerData.error[key] === '' ? false : true}
                              helperText={registerData.error[key] === '' ? helperText[key] : ''}
                              className={classes.error}
                              label={`${displayName[key]} *`}
                              name={key}
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              style={{backgroundColor: registerData.error[key] === '' ? '#FFFFFF' : '#FEF7F5'}}
                            />
                          )}
                        />
                        {registerData.error[key] !== '' && registerData.error[key] !== 'Required' && (
                          <p
                            className="Error"
                            style={{
                              color: '#E05757',
                              fontSize: '14px',
                            }}
                          >
                            <ErrorOutlineIcon
                              style={{
                                fontSize: '14px',
                                marginTop: '-4px',
                                marginRight: '10px',
                              }}
                            />
                            {registerData.error[key]}
                          </p>
                        )}
                      </Grid>
                    );
                  else if (fieldType[key] === 'select' && key === 'city')
                    return (
                      <Grid sm={6} xs={12} item key={key}>
                        <Autocomplete
                          options={props.dropDownCityList.length ? props.dropDownCityList : []}
                          getOptionLabel={option => (typeof option !== 'string' ? option.city_name : option)}
                          className={classes.inputRoot}
                          value={props.dropDownCityList.length ? (registerData.data.city ? registerData.data.city : null) : null}
                          disabled={props.dropDownCityList.length ? false : true}
                          onChange={onTagsCityChange}
                          renderInput={params => (
                            <TextField
                              {...params}
                              error={registerData.error[key] === '' ? false : true}
                              helperText={registerData.error[key] === '' ? helperText[key] : ''}
                              className={classes.error}
                              label={`${displayName[key]} *`}
                              name={key}
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              style={{backgroundColor: registerData.error[key] === '' ? '#FFFFFF' : '#FEF7F5'}}
                            />
                          )}
                        />
                        {registerData.error[key] !== '' && registerData.error[key] !== 'Required' && (
                          <p
                            className="Error"
                            style={{
                              color: '#E05757',
                              fontSize: '14px',
                            }}
                          >
                            <ErrorOutlineIcon
                              style={{
                                fontSize: '14px',
                                marginTop: '-4px',
                                marginRight: '10px',
                              }}
                            />
                            {registerData.error[key]}
                          </p>
                        )}
                      </Grid>
                    );
                  else return <h1>No Field </h1>;
                })}
                <Grid xs={12} sm={6} style={{paddingTop: '0px'}} item>
                  <p className={classes.requiredText}>All fields marked with asterisk ( * ) are required. </p>
                  <Button disabled={loader} variant="contained" type="submit" className="default-btn" color="primary" margin="normal" fullWidth>
                    Register {loader && <SubmitLoader />}
                  </Button>
                </Grid>
              </Grid>
            </form>
            <div className="signup-link">
              Already Have An Account? <a href="/signin">Login Here</a>
            </div>
            <ReCAPTCHA sitekey={config.recaptchaSiteKey} size="invisible" ref={reRef} />
          </div>
        </div>
      </div>
    </div>
  );
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      userSignUp,
      fetchDropdownStateList,
      fetchDropdownCityList,
      dropdownCityListSuccess,
      sendPrefilledSignupValue,
      fetchCityStateByZip,
    },
    dispatch,
  );
};

const mapStateToProps = ({auth, error, certifiedinstaller}) => {
  const {loader, authUser, signinStatus, userRole, clientConfigration, dropDownCityList, createLeadResponseData, cityStateName} = auth;
  const {certifiedInstallerState} = certifiedinstaller;
  const {errorDisplay, errorMessage, pageName} = error;
  return {
    loader,
    authUser,
    signinStatus,
    userRole,
    clientConfigration,
    errorDisplay,
    errorMessage,
    pageName,
    certifiedInstallerState,
    dropDownCityList,
    createLeadResponseData,
    cityStateName,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
