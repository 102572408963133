import {FETCH_ERROR, FETCH_START, FETCH_SUCCESS, HIDE_MESSAGE, SHOW_MESSAGE, SHOW_HIDE_IDLE_MODAL} from '../../constants/ActionTypes';

const INIT_STATE = {
  error: '',
  loading: false,
  message: '',
  idleModal: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {...state, error: '', message: '', loading: true};
    }
    case FETCH_SUCCESS: {
      return {...state, error: '', message: '', loading: false};
    }
    case SHOW_MESSAGE: {
      return {...state, error: '', message: action.payload, loading: false};
    }
    case FETCH_ERROR: {
      return {...state, loading: false, error: action.payload, message: ''};
    }
    case HIDE_MESSAGE: {
      return {...state, loading: false, error: '', message: ''};
    }
    case SHOW_HIDE_IDLE_MODAL: {
      return {
        ...state,
        idleModal: action.payload,
      };
    }
    default:
      return state;
  }
};
