import React, {useState,useRef} from 'react';
import AddIcon from '@material-ui/icons/Add';
import {FaFacebookF} from 'react-icons/fa';
import {FaTwitter} from 'react-icons/fa';
import {FaLinkedinIn} from 'react-icons/fa';
import {ImInstagram} from 'react-icons/im';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import footer_certifications from '../../assets/images/footer_certifications.png';
import ReCAPTCHA from 'react-google-recaptcha';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import config from '../../config/config';
import {sendFooterMail} from '../../redux/actions/CertifiedInstaller';
import { ImCheckmark } from 'react-icons/im';


const FooterForCertifiedInstaller = props => {
  const {sendFooterMailStatus} = props;

  const reRef = useRef();
  const [myEmail, setMyEmail] = useState('');

  //Onsubmit email sent to server
  const handleSubmit = async event => {
    event.preventDefault();
    const recatpchaToken = await reRef.current.executeAsync();
    reRef.current.reset();

    props.sendFooterMail({
      FormID: 'Newsletter',
      'E-mail': myEmail,
      'g-recaptcha-response': recatpchaToken,
    });
  };

  //OnChange for email field
  const handleChange = async event => {
    event.preventDefault();
    setMyEmail(event.target.value);
  };

  return (
    <div className="FooterForCertifiedInstaller-wrapper">
      <footer>
        <hr className="hr-footer-top" />
        <div className="container containerXL p-lg-0">
          <div className="row flex-row">
            <div className="col-lg-5 col-xs-12">
              <div className="row flex-row">
                <div className="col-lg-4 col-sm-6 col-xs-12">
                  <ul className="footer-nav">
                    <li>
                      <a target="_self" href="http://www.amerikooler.com/Products/" className="dropdown">
                        <strong>Products</strong>
                      </a>
                    </li>
                    <li>
                      <a target="_self" href="http://www.amerikooler.com/BuyNow/" className="dropdown">
                        <strong>Buy Now</strong>
                      </a>
                    </li>
                    <li>
                      <a target="_self" href="http://www.amerikooler.com/Resources/" className="dropdown">
                        Resources
                      </a>
                    </li>
                    <li>
                      <a href="http://www.partstown.com/amerikooler/parts" className="dropdown">
                        Parts Store
                      </a>
                    </li>
                    <li>
                      <a target="_self" href="http://www.amerikooler.com/About/" className="dropdown">
                        About
                      </a>
                    </li>
                    <li>
                      <a target="_self" href="http://www.amerikooler.com/news/" className="dropdown">
                        News
                      </a>
                    </li>
                    <li>
                      <a target="_self" href="http://www.amerikooler.com/Contact/" className="dropdown">
                        Contact
                      </a>
                    </li>

                    <li>
                      <a href="https://extranet.amerikooler.com/login.aspx?ReturnUrl=/default.aspx" className="dropdown">
                        <strong>login</strong>
                      </a>{' '}
                      |{' '}
                      <a href="https://extranet.amerikooler.com/Docs/ExtranetAccessForm.pdf" className="dropdown">
                        <strong>register</strong>
                      </a>
                    </li>
                  </ul>
                  <div className="mbl-hide ">
                    <a href="https://www.thinkmoncur.com/" className="transition-effect">
                      <img src="/img/moncur_logo.png" alt="loading..." className="moncur-logo" />
                      <div className="sweepable-text">Website by Moncur</div>
                    </a>
                  </div>
                  <br />
                </div>

                <div className="col-lg-8 col-sm-6 col-xs-12 footer-actions pt-2">
                  <a href="http://www.amerikooler.com/BuyNow/Get-a-Quote" className=" btn-action hvr-sweep-to-left">
                    <span>GET A QUOTE</span>
                    <span className="DoubleArrowIcon-wrapper">
                      <DoubleArrowIcon />
                    </span>
                  </a>
                  <a href="http://store.amerikooler.com/dealers" className="btn-action hvr-sweep-to-left">
                    <span>FIND A DEALER</span>
                    <span className="DoubleArrowIcon-wrapper">
                      <DoubleArrowIcon />
                    </span>
                  </a>
                  <a href="http://www.amerikooler.com/BuyNow/Find-a-SalesRep" className="btn-action hvr-sweep-to-left">
                    <span>FIND A SALES REP</span>
                    <span className="DoubleArrowIcon-wrapper">
                      <DoubleArrowIcon />
                    </span>
                  </a>
                  <br />
                </div>
              </div>
            </div>

            <div className="col-xl-7 col-xl-offset-0 col-lg-7 col-lg-offset-1 col-xs-12">
              <div className="row flex-row justify-content-between">
                <div className="col-lg-6 col-sm-5 col-xs-12 px-3">
                  <p>
                    <strong>sign up for our newsletter</strong>
                    <br />
                    it's pretty kool
                  </p>
                  {sendFooterMailStatus && (
                    <div className="Footer1_Newsletter_Signup_MessageSender_pnlMessageSuccess">
                      <div className="icon-wrapper">
                        <ImCheckmark className="icon" />
                      </div>
                      <p>
                        Your message was <br /> successfully sent!
                      </p>
                    </div>
                  )}
                  {/* <form id="newsletter-signup"> */}
                  <form className="signup flex-row" onSubmit={handleSubmit} id="newsletter-signup">
                    <div className="sect-input">
                      <input name="FormID" type="hidden" value="Newsletter" />
                      <input
                        type="email"
                        name="email"
                        placeholder="ENTER EMAIL"
                        value={myEmail}
                        title="Email Address"
                        onChange={handleChange}
                        required="required"
                      />
                      <button type="submit">
                        <strong>
                          <AddIcon style={{color: 'white'}} />
                        </strong>
                      </button>
                    </div>
                  </form>
                  <br />
                  <br />
                  <p>
                    <strong>certifications</strong>
                  </p>
                  <div>
                    <img src={footer_certifications} alt="loading..." className="cerifications-image" />
                  </div>
                  <br />
                  <br />
                </div>

                <div className="col-xl-5 col-xl-offset-1 col-lg-5 col-lg-offset-1 col-sm-6 col-sm-offset-1 col-xs-12">
                  <div className="footer-social">
                    <p>
                      <a className="text-lg text-dark h2" href="tel:18006275665">
                        <strong className="hoverBlue">1.800.627.5665</strong>
                      </a>
                      <br />
                      <a className="text-dark hoverBlue" href="mailto:salesdept@amerikooler.net">
                        <span className="hoverBlue">salesdept@amerikooler.net</span>
                      </a>
                    </p>
                    <ul className="social list-inline d-flex">
                      <li>
                        <a href="https://www.facebook.com/Amerikooler/">
                          <FaFacebookF className="footer-social-links-logo hoverBlue" />
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/amerikooler">
                          <FaTwitter className="footer-social-links-logo hoverBlue" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/amerikooler-inc.">
                          <FaLinkedinIn className="footer-social-links-logo hoverBlue" />
                        </a>
                      </li>
                    </ul>

                    <div>
                      <strong>interested in following our CEO?</strong>
                    </div>
                    <ul className="list-inline d-flex">
                      <li>
                        <a href="https://www.instagram.com/mrmanufacturing/">
                          <ImInstagram className="footer-social-media-logo" />
                        </a>
                      </li>
                      <li>
                        &nbsp;{' '}
                        <a href="https://www.linkedin.com/in/gcalonso/">
                          <FaLinkedinIn className="footer-social-media-logo" />
                        </a>
                      </li>
                      <li>
                        &nbsp;{' '}
                        <a href="https://twitter.com/mrmanufacturing">
                          <FaTwitter className="footer-social-media-logo" />
                        </a>
                      </li>
                    </ul>
                    <br />
                    <br />
                    <ul className="list-inline list-bottom">
                      <li className="">
                        <a href="http://www.amerikooler.com/About/terms" className="hoverBlue">
                          Terms of Use
                        </a>
                      </li>
                      <li className="">
                        <a href="http://www.amerikooler.com/About/privacy" className="hoverBlue">
                          Privacy
                        </a>
                      </li>
                      <li className="">
                        <a href="http://www.amerikooler.com/About/sitemap" className="border-none hoverBlue">
                          Sitemap
                        </a>
                      </li>
                    </ul>
                    <p className="copyright">© Copyright 2022 Amerikooler. All rights reserved.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="des-hide">
            <a href="https://www.thinkmoncur.com/" className="moncur-logo-wrapper transition-effect ">
              <img src="/img/moncur_logo.png" alt="loading..." className="moncur-logo" /> <div className="sweepable-text">Website by Moncur</div>
            </a>
          </div>
          <ReCAPTCHA sitekey={config.recaptchaSiteKey} size="invisible" ref={reRef} />
        </div>
      </footer>
    </div>
  );
};

// export default FooterForCertifiedInstaller;
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      sendFooterMail,
    },
    dispatch,
  );
};

const mapStateToProps = ({auth, certifiedinstaller}) => {
  const {clientConfigration} = auth;
  const {sendFooterMailStatus} = certifiedinstaller;

  return {
    clientConfigration,
    sendFooterMailStatus,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterForCertifiedInstaller);
