import React from 'react';
import Alert from '@material-ui/lab/Alert';
import SubmitLoader from 'components/SubmitLoader/SubmitLoader';
import {connect} from 'react-redux';
import {resendEmailVerificationLink} from 'redux/actions/Auth';
import {fetchProfileData} from 'redux/actions/Profile';
import {bindActionCreators} from 'redux';
import {makeStyles} from '@material-ui/core/styles';
import './HeaderAlert.scss';
import {useEffect} from 'react';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  message: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const HeaderAlert = ({resendEmailLoader, resendEmailVerificationLink, userId, profileData}) => {
  const {email, hash} = !!profileData && profileData;
  const classes = useStyles();

  useEffect(() => {
    fetchProfileData();
  });
  return (
    <div className='HeaderAlertWrapper'>
      <Alert
        variant="filled"
        severity="error"
        classes={{
          root: classes.root,
          icon: classes.icon,
          message: classes.message,
        }}
      >
     <span className='message'>Confirm Your Email Address! Please check your email to verify your account.</span>
        <button
          type="button"
          className="resend-default-btn"
          disabled={resendEmailLoader}
          onClick={() => {
            resendEmailVerificationLink({emai: email, user_id: userId, hash: hash});
          }}
        >
          Resend Verification Link {resendEmailLoader && <SubmitLoader />}
        </button>
      </Alert>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      resendEmailVerificationLink,
      fetchProfileData,
    },
    dispatch,
  );
};

const mapStateToProps = ({auth, profile}) => {
  const {emailVerifiedStatus, loader, userId, resendEmailLoader} = auth;
  const {profileData} = profile;

  return {
    loader,
    emailVerifiedStatus,
    userId,
    resendEmailLoader,
    profileData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderAlert);
