import React from 'react';
import './ResetPasswordError.scss';
import GreetingHeader from 'components/GreetingHeader/GreetingHeader';
import {connect} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import {RiErrorWarningLine} from 'react-icons/ri';

const ResetPasswordError = props => {
  const {loader, history} = props;

  return (
    <React.Fragment>
      <div className="app-container pre-resetpassworderror-wrapper">
        <div className="app-main-container">
          <div className="header-logo-hideInMobile">
            <GreetingHeader />
          </div>

          {loader ? (
            <div className="loaderForConfigrationPage">
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="container d-flex flex-column justify-content-center vh-100 ">
                <div className="row justify-content-center">
                  <div className="col-md-7 ">
                    <div className="card">
                      <div className="card-content">
                        <div className="parent-frame-icon">
                          <div className="frame-icon">
                            <RiErrorWarningLine className="warningIcon" />
                          </div>
                        </div>

                        <div className="heading">
                          <span> This reset password Link has been expired, please generate new reset password link.</span>
                          <hr />
                          <div className="box-content alert-box-content">
                            <p>Forgot your password?</p>
                          </div>
                        </div>

                        <button
                          type="button"
                          className="default-btn"
                          disabled={loader}
                          onClick={() => {
                            history.push('/forgot-password');
                          }}
                        >
                          FORGET PASSWORD
                        </button>
                        <div className="signin-link">
                          Already Registered? <a href="/signin">Login Here</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{position: 'absolute', left: '0px', bottom: '0px'}} className="hideOnMobile">
                <img className="sideHeaderImage" alt="icon" src={process.env.PUBLIC_URL + '../../../../img/side-footer.svg'} />
              </div>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({auth}) => {
  const {loader} = auth;

  return {
    loader,
  };
};

export default connect(mapStateToProps, null)(ResetPasswordError);
